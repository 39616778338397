import React, { useEffect, useState } from 'react';
import { Card, Avatar, List, Tag, Tooltip, Badge, Space, Collapse } from 'antd';
import { ClasificationVm, ConversationVm, FilterOrderProps } from '../../common/types';
import '../../Assets/Styles/navMenu.less';
import ConversationService from '../../Services/ConversationService';
import { groupBy } from 'lodash';
import { TagTwoTone } from '@ant-design/icons';
import { Whatsapp } from '../../Assets/Icons/IconsSolid';
import { ConversationRealTime } from '../RealTimeData';

const { Panel } = Collapse;

interface ITableConversationProp {
  selectedKey: number | undefined;
  listItemClick: (key: number, entityId: number, service: string) => void;
  filterOrderProps: FilterOrderProps;
  clasification: ClasificationVm | undefined;
}

const TableConversations: React.FC<ITableConversationProp> = (
  { listItemClick, selectedKey, filterOrderProps, clasification }: ITableConversationProp) => {
  const [items, setItems] = useState<JSX.Element[]>();

  const timeDiffCalc = (lastMessageTimeStamp: Date): string => {
    const dif = +(new Date(lastMessageTimeStamp)) - +(new Date());
    let diffInMilliSeconds = Math.abs(dif) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    if (days > 0) return `${days} d`;
    if (hours > 0) return `${hours} h`;
    if (minutes > 0) return `${minutes} m`;

    return '';
  };

  const description = (item: ConversationVm): JSX.Element => {
    return <>
      <div style={{ fontSize: 12 }}>
        {item?.lastMessage?.length > 38 ? `${item.lastMessage.slice(0, 38).trimEnd()}...` : item.lastMessage}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'left', flexWrap: 'nowrap', justifyContent: 'space-between' }} >
        <Tooltip title={item.service}>
          <TagTwoTone style={{ marginLeft: -40, marginRight: 40, display: 'flex', alignItems: 'center' }} twoToneColor={item?.service?.includes('Servicio') ? '#52c41a' : '#ff4040'} rotate={45} />
        </Tooltip>
        <div style={{ color: 'red', marginLeft: -54 }}>{item.from}</div>
        <Tag color='default'>{item.service}</Tag>
      </div>
    </>;
  };

  const collapsed = (items: ConversationVm[], key: string, childs: JSX.Element[]): JSX.Element => {
    return <Collapse key={key} defaultActiveKey={key}>
      <Panel header={key} key={key} style={{ border: 'none' }}>
        {childs}
      </Panel>
    </Collapse>;
  };
  useEffect(() => {
    const conversationService = new ConversationService();

    const title = (item: ConversationVm): JSX.Element => {
      return <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between' }} >
        <div >
          {item.unread && <Badge status='error' />}
          {item.entityFullName}
        </div>
        <div style={{ fontSize: 12 }}>
          <Space align='start' size='small' style={{ fontWeight: 'lighter' }}>
            {timeDiffCalc(item.lastMessageTimeStamp)} <Whatsapp />
          </Space>
        </div>
      </div>;
    };

    const cardGrid = (item: ConversationVm): JSX.Element => {
      return <List.Item key={item.id} style={{ marginTop: 0, marginBottom: 0, padding: 0, border: 'none' }}
        onClick={() => listItemClick(item.id, item.entityId, item.service)}>
        <div id='ant'>
          <Card.Grid className='ant-menu-item' hoverable
            style={{
              backgroundColor: item.id === selectedKey ? '#ffedeb' : '#fff',
              borderLeft: item.id === selectedKey ? '3px solid #e61b26' : 'none',
              width: '100%',
              paddingTop: 5,
              paddingBottom: 5,
              paddingLeft: 5,
              paddingRight: 0,
              margin: 0
            }}>
            <List.Item.Meta avatar={<Avatar>
              {item.entityFullName.trim().split(' ').map((n, i, a) => i === 0 || i + 1 === a.length ? n[0] : null).join('')}
            </Avatar>}
              title={title(item)}
              description={description(item)} />
          </Card.Grid>
        </div>
      </List.Item>;
    };

    const init = async (): Promise<void> => {
      const { textSearch, serviceFilterKeys } = filterOrderProps;
      let result = await conversationService.getByUser(serviceFilterKeys, filterOrderProps.order, filterOrderProps.statusId);
      if (textSearch !== '') result = result.filter((item) => item.entityFullName.toLowerCase().includes(textSearch.toLowerCase()) || item.from.includes(textSearch));
      const data = groupBy(result, filterOrderProps.groupProperty);
      const collapsedItems: JSX.Element[] = [];

      for (const key in data) {
        const cardGrids: JSX.Element[] = [];
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          const thisGroup = data[key];
          thisGroup.forEach(function (item: any) {
            cardGrids.push(
              cardGrid(item)
            );
          });
          collapsedItems.push(
            collapsed(thisGroup, key, cardGrids)
          );
        }
      }

      setItems(collapsedItems.length > 0 ? collapsedItems : undefined);
    };
    init();
  }, [filterOrderProps, filterOrderProps.groupProperty, listItemClick, selectedKey, clasification]);

  const reload = async (): Promise<void> => {
    const title = (item: ConversationVm): JSX.Element => {
      return <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between' }} >
        <div >
          {item.unread && <Badge status='error' />}
          {item.entityFullName}
        </div>
        <div style={{ fontSize: 12 }}>
          <Space align='start' size='small' style={{ fontWeight: 'lighter' }}>
            {timeDiffCalc(item.lastMessageTimeStamp)} <Whatsapp />
          </Space>
        </div>
      </div>;
    };

    const cardGrid = (item: ConversationVm): JSX.Element => {
      return <List.Item key={item.id} style={{ marginTop: 0, marginBottom: 0, padding: 0, border: 'none' }}
        onClick={() => listItemClick(item.id, item.entityId, item.service)}>
        <div id='ant'>
          <Card.Grid className='ant-menu-item' hoverable
            style={{
              backgroundColor: item.id === selectedKey ? '#ffedeb' : '#fff',
              borderLeft: item.id === selectedKey ? '3px solid #e61b26' : 'none',
              width: '100%',
              paddingTop: 5,
              paddingBottom: 5,
              paddingLeft: 5,
              paddingRight: 0,
              margin: 0
            }}>
            <List.Item.Meta avatar={<Avatar>
              {item.entityFullName.trim().split(' ').map((n, i, a) => i === 0 || i + 1 === a.length ? n[0] : null).join('')}
            </Avatar>}
              title={title(item)}
              description={description(item)} />
          </Card.Grid>
        </div>
      </List.Item>;
    };

    const conversationService = new ConversationService();

    const { textSearch, serviceFilterKeys } = filterOrderProps;
    let result = await conversationService.getByUser(serviceFilterKeys, filterOrderProps.order, filterOrderProps.statusId);
    if (textSearch !== '') result = result.filter((item) => item.entityFullName.toLowerCase().includes(textSearch.toLowerCase()) || item.from.includes(textSearch));
    const data = groupBy(result, filterOrderProps.groupProperty);
    const collapsedItems: JSX.Element[] = [];

    for (const key in data) {
      const cardGrids: JSX.Element[] = [];
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const thisGroup = data[key];
        thisGroup.forEach(function (item: any) {
          cardGrids.push(
            cardGrid(item)
          );
        });
        collapsedItems.push(
          collapsed(thisGroup, key, cardGrids)
        );
      }
    }

    setItems(collapsedItems.length > 0 ? collapsedItems : undefined);
  };
  return <>
    <List>
      {items?.map(function (item: JSX.Element) {
        return item;
      })}
    </List>
    <ConversationRealTime reload={reload}></ConversationRealTime>
  </>;
};

export default TableConversations;
