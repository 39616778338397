import { Button, Col, Form, notification, Row } from 'antd';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ContactResultType } from '../../common/types';
import { Result } from '../../Services/Models/Result';
import { ValidationErrors } from '../../Services/Models/ValidationErrors';
import ComponentFactory from './ComponentFactory';
import { FieldProps } from './FieldProps';
import { FormSchema } from './FormSchema';
import moment from 'moment';

export interface DynamicFormProps {
  schema: FormSchema;
  onSubmit: (data: { [x: string]: unknown }) => void;
  onReset: () => void;
  submitButtonText: string;
  cancelButtonText: string;
  validationErrors?: ValidationErrors;
  serverErrors?: Result<unknown>;
  data?: ContactResultType;
}

const DynamicForm: React.FC<DynamicFormProps> = ({ schema, onSubmit, onReset, data, cancelButtonText = 'cancel', submitButtonText = 'save', validationErrors, serverErrors }: DynamicFormProps) => {
  const { t } = useTranslation();
  const { groups } = schema;
  const [form] = Form.useForm();
  const fieldsMemo: Record<string, FieldProps> = {};

  groups?.flatMap(g => g.fields)
    .forEach((f): void => { fieldsMemo[f.internalName] = f; });

  const { register, handleSubmit, setValue, reset } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    resolver: undefined,
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false
  });

  const formatData = (data: Record<string, unknown>): { dynamicData: Record<string, unknown>[]; } => {
    const formattedData: Record<string, unknown> = {};
    const dynamicData: Record<string, unknown>[] = [];
    Object.keys(data).forEach(key => {
      if (!fieldsMemo[key]?.isDynamic) {
        formattedData[key] = data[key];
      } else if (data[key] !== null && data[key] !== undefined) {
        dynamicData.push({
          fieldId: fieldsMemo[key].fieldId,
          value: Array.isArray(data[key]) ? JSON.stringify(data[key]) : data[key],
          internalName: key
        });
      }
    });

    return {
      ...formattedData,
      dynamicData
    };
  };

  const submitWrapper = (data: Record<string, unknown>): void => {
    onSubmit(formatData(data));
  };

  const onResetWrapper = (): void => {
    reset({});
    onReset();
  };

  useEffect(() => {
    if (serverErrors && serverErrors.Error) {
      notification.error({
        message: t('errors.serverError'),
        description: serverErrors.Error
      });
    }
  }, [serverErrors, t]);

  useEffect(() => {
    if (data) {
      for (const [key, value] of Object.entries(data)) {
        const component = form.getFieldInstance(key);
        if (component) {
          component.pickerRef !== undefined
            ? form.setFieldsValue({ [key]: moment('' + value, 'YYYY-MM-DD') })
            : form.setFieldsValue({ [key]: [value] });

          setValue(key, value);
        }
      }
    } else {
      groups?.forEach(group =>
        group.fields.forEach(field => {
          if (field.controlType === 'single-checkbox') { form.setFieldsValue({ [field.internalName]: false }); }
        }));
    }
  }, [data, setValue, form, groups, schema]);

  const getChangeHandlerWithValue = (name: string) => (value: unknown) => {
    setValue(name, value);
  };

  const renderFields = (fields: FieldProps[]): JSX.Element[] => {
    const orderedFields = fields.sort((a, b) => a.position - b.position);
    return orderedFields.map(field =>
      <ComponentFactory
        key={`cf-${field.internalName}`}
        id={data?.id as number}
        companyAccountId={data?.companyAccountId as string}
        field={field}
        register={register}
        onChange={getChangeHandlerWithValue}
        validationErrors={validationErrors}
      />
    );
  };

  return (
    <Row>
      <Col span='24'>
        <Form form={form} layout='vertical' requiredMark className='relative' onSubmitCapture={handleSubmit(submitWrapper)}>
          {
            groups?.map(group => (
              <Row gutter={16} key={`gp-${group.position}`}>
                {renderFields(group.fields)}
              </Row>
            ))
          }
          <div className='button-container-group'>
            <Button type='primary' htmlType='submit'>{t(submitButtonText)}</Button>
            <Button htmlType='reset' onClick={onResetWrapper}>{t(cancelButtonText)}</Button>
          </div>
        </Form>
      </Col>
    </Row>
  );
};

export default DynamicForm;
