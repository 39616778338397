import React, { useState } from 'react';
import { Menu } from 'antd';
import ContactFormAssembly from '../ContactFormAssembly';
import { ModeForm } from '../../Constants/Enums';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import SiderResisable from '../SiderResizable';
import debounce from 'lodash.debounce';

interface props {
  drawerWidth: number,
  entityId: number | undefined,
  titleContact: string,
  updateTitle: (contactTitle: string) => void
}
const RigthSiderResizable: React.FC<props> = ({
  titleContact, updateTitle, entityId
}: props) => {
  const chatRoomWidthKey = 'chatRoomContact';
  const [drawerWidth, setDrawerWidth] = useState(parseInt(localStorage.getItem(chatRoomWidthKey) || '320'));
  const [contactCollapsed, setContactCollapsed] = useState<boolean>(true);
  const onContactCollapsed = (): void => setContactCollapsed(!contactCollapsed);
  const onClose = (dummyparameter: boolean): void => setContactCollapsed(!contactCollapsed);

  const debouncedSaveWidth =
    debounce(nextValue => {
      localStorage.setItem(chatRoomWidthKey, nextValue);
    }, 1000);

  const onDrawerResize = (sizePx: number): void => {
    setDrawerWidth(sizePx);
    debouncedSaveWidth(sizePx);
  };

  return <SiderResisable
    sideGrow='left'
    collapsible
    collapsed={contactCollapsed}
    trigger={null}
    collapsedWidth={40}
    onResize={onDrawerResize} width={drawerWidth} minWidth={294} maxWidth={800}
    style={{ overflow: 'auto', paddingLeft: 5, paddingRight: 5, paddingBottom: 5, height: '82vh', position: 'sticky', display: 'inline-block', left: 0, backgroundColor: '#f7f7f7' }}>
    <Menu style={{ border: 'none', background: '#f7f7f7' }} mode='inline' selectedKeys={['']}>
      <Menu.Item key='0' onClick={onContactCollapsed} icon={contactCollapsed ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}>
        Información del cliente
      </Menu.Item>
    </Menu>
    <ContactFormAssembly onClose={onClose} visible={!contactCollapsed} modeFormId={ModeForm.Edit} title={titleContact} id={entityId} updateTitle={updateTitle} />
  </SiderResisable>;
};

export default RigthSiderResizable;
