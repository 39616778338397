import React from 'react';
import { useAuth } from 'oidc-react';
import { RouteComponentProps } from 'react-router-dom';

const Callback: React.FC<RouteComponentProps> = ({ history }: RouteComponentProps) => {
  const auth = useAuth();
  auth?.userManager?.signinRedirectCallback().then(() => {
    history.push('/');
  });

  return (
    <div className='generic-container'>
      <h2>Redireccionando... </h2>
    </div>
  );
};

export default Callback;
