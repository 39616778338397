import React, { useState } from 'react';
import '../Assets/Styles/navMenu.less';
import { Layout, Row, Space, Divider, Typography } from 'antd';
import { SelectEventHandler } from 'rc-menu/lib/interface';
import DrawerContactForm from '../Components/DrawerContactForm';
import InfiniteScrollReverse from '../Components/Scrollers/InfiniteScrollReverse';
import HeaderChat from '../Components/ChatRooms/HeaderChat';
import ChatRoomBanner from '../Components/ChatRooms/ChatRoomBanner';
import ConversationListResizable from '../Components/ChatRooms/SiderConversationResizable';
import ConversationTrayResizable from '../Components/ChatRooms/ConversationSiderResisable';
import RigthSiderResizable from '../Components/ChatRooms/RigthSiderResizable';
import { useAuth } from 'oidc-react';
import { ClasificationVm } from '../common/types';
import { StatusConversation } from '../Constants/Enums';

const ChatRoom: React.FC = () => {
  const auth = useAuth();

  const { Content } = Layout;
  const [isStatusConversationChanged, setStatusConversationChanged] = useState<boolean>(false);
  const [isContactFormOpen, setIsContactFormOpen] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [selectedKey, setSelectedKey] = useState<number>();
  const [entityId, setEntityId] = useState<number>();
  const [selectedKeys, setSelectedKeys] = useState<Array<string>>([StatusConversation.all.toString()]);
  const [serviceFilterKeys, setServiceFilterKeys] = useState<Array<number>>([]);
  const [titleContact, setTitle] = useState('');
  const [serviceName, setServiceName] = useState('');
  const [searchText, setSearchText] = useState('');
  const [clasification, setClasification] = useState<ClasificationVm>();
  const [traySelected, setTraySelected] = useState<number>(StatusConversation.all);

  const chatRoomWidthKey = 'chatRoomConversation';
  const [drawerWidth] = useState(parseInt(localStorage.getItem(chatRoomWidthKey) || '320'));

  // #region functions
  const statusChanged = (): void => setStatusConversationChanged(!isStatusConversationChanged);
  const openContactForm = (): void => setIsContactFormOpen(true);
  const closeContactForm = (): void => setIsContactFormOpen(false);

  const listItemClick = (key: number, entityId: number, service: string): void => {
    setSearchText('');
    setSelectedKey(key);
    setServiceName(service);
    setEntityId(entityId);
  };

  const onCollapsed = (): void => setCollapsed(!collapsed);

  const onSelect: SelectEventHandler | undefined = (e) => {
    if (e.selectedKeys !== undefined && e.selectedKeys[0] !== '-1') {
      setSelectedKey(undefined);
      const selectedTrayKey = e.selectedKeys[0];
      const selected = e.selectedKeys.map(String);
      setSelectedKeys(selected);
      setTraySelected(+selectedTrayKey);
    }
  };

  const updateTitle = (contactTitle: string): void => {
    setTitle(contactTitle);
  };

  const onServiceFilterChange = (values: number[]): void => {
    setServiceFilterKeys(values);
  };

  const onSearch = (searchText: string): void => {
    setSearchText(searchText);
  };

  const clasificationChange = (selectedKey: number, clasificationId: number): void => {
    setClasification({ selectedKey, clasificationId });
  };

  // #endregion

  const { Text } = Typography;

  return (
    <div style={{ height: '100%' }}>
      <ChatRoomBanner onServiceFilterChange={onServiceFilterChange} openContactForm={openContactForm} />
      <Row>
        <Layout>
          <ConversationTrayResizable collapsed={collapsed} onSelect={onSelect} onCollapsed={onCollapsed} selectedKeys={selectedKeys} isStatusChanged={isStatusConversationChanged} />
          <ConversationListResizable serviceFilterKeys={serviceFilterKeys} clasification={clasification} selectedKey={selectedKey} listItemClick={listItemClick}
            traySelected={traySelected}
          />
          <Content style={{ backgroundColor: '#fff', overflow: 'initial' }}>
            <Space direction='vertical'>
              <Text type='secondary'>{serviceName}</Text>
              <Text strong>{auth?.userData?.profile.name}</Text>
            </Space>
            <Layout.Header className='ant-layout-header-chatroom'>
              <HeaderChat onSearch={onSearch} clasificationChange={clasificationChange} selectedKey={selectedKey} statusChanged={statusChanged} />
            </Layout.Header>
            <Divider style={{ marginBottom: 0, paddingBottom: 0, marginTop: 0, paddingTop: 0 }} />
            <InfiniteScrollReverse searchText={searchText} selectedKey={selectedKey} />
          </Content>
          <RigthSiderResizable entityId={entityId} drawerWidth={drawerWidth} titleContact={titleContact} updateTitle={updateTitle} />
        </Layout>
      </Row>
      {/* Form Creación de contacto */}
      <DrawerContactForm
        isOpen={isContactFormOpen}
        onClose={closeContactForm}
      />
    </div>);
};

export default ChatRoom;
