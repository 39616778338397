import { Col, Row } from 'antd';
import React from 'react';
import '../Assets/Styles/banner.less';

interface props {
  children: React.ReactNode
}

const Banner: React.FC<props> = ({ children }: props) => (
  <Row className='generic-container banner-background'>
    <Col className='banner'>
      {children}
    </Col>
  </Row>
);

export default Banner;
