import { Modal, Button, Table } from 'antd';
import React, { useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table/Table';
import Search from 'antd/lib/input/Search';
import CreateView from './CreateView';
import { PlusSquareOutlined } from '@ant-design/icons';
import { useModal } from './UseModal';
import APIClient from '../../Services/APIClient';
import { ModuleId, ShareTypeId } from '../../Constants/Enums';
import { SelectionSelectFn } from 'antd/lib/table/interface';
import { IDataView, IViewBase, INewViewProps } from '../../common/types';

const WrappedComponent: React.FC<INewViewProps> = ({ reloadViews, getViews }: INewViewProps) => {
  const { t } = useTranslation();
  const { isShown, toggle } = useModal();
  const [visible, setVisible] = useState(Boolean);
  const [views, setViews] = useState([]);
  const [privateViews, setPrivateViews] = useState([]);
  const [publicViews, setPublicViews] = useState([]);
  const [modelBase, setModelBase] = useState<IViewBase>({ isDefault: 0 });
  const [selectedRowKeys, setSelectedRowKeys] = useState(Array<number>());

  async function getDataViews (): Promise<void> {
    const apiClient = new APIClient();
    const result = await apiClient.client.get(`/DataView/GetAll?moduleId=${ModuleId.Contacto}`);
    const fPrivate = result.data.filter((view: IDataView) => view.shareTypeId === ShareTypeId.Privado);
    const fpublic = result.data.filter((view: IDataView) => view.shareTypeId === ShareTypeId.Publico);
    setViews(result.data);
    setPublicViews(fpublic);
    setPrivateViews(fPrivate);
  }

  const showModal = (): void => {
    setVisible(true);
    setSelectedRowKeys([]);
    getDataViews();
  };

  const handleOk = (): void => {
    setVisible(false);
    toggle();
  };

  const handleSelectView = (): void => {
    if (selectedRowKeys.length > 0) {
      getViews(selectedRowKeys[0]);
    }
    setVisible(false);
  };

  const handleCancel = (): void => {
    setVisible(false);
  };

  const columns: ColumnsType<IDataView> = [{
    key: 'id',
    title: 'Name',
    dataIndex: 'name'
  }];

  const onSelect: SelectionSelectFn<IDataView> = (record: IDataView) => {
    setSelectedRowKeys([record.id]);
    setModelBase({ isDefault: 0, modelBaseId: record.id });
  };

  const rowSelection = {
    hideSelectAll: false,
    selectedRowKeys: selectedRowKeys,
    onSelect: onSelect
  };

  const filterTable = (filterText: string): void => {
    if (filterText.length > 0) {
      let fViews = views.filter((view: IDataView) => view.name.toLowerCase().indexOf(filterText.toLowerCase()) > -1 && view.shareTypeId === ShareTypeId.Privado);
      setPrivateViews(fViews);

      fViews = views.filter((view: IDataView) => view.name.toLowerCase().indexOf(filterText.toLowerCase()) > -1 && view.shareTypeId === ShareTypeId.Publico);
      setPublicViews(fViews);
    } else {
      const fPrivate = views.filter((view: IDataView) => view.shareTypeId === ShareTypeId.Privado);
      const fpublic = views.filter((view: IDataView) => view.shareTypeId === ShareTypeId.Publico);
      setPublicViews(fpublic);
      setPrivateViews(fPrivate);
    }
  };

  return (
    <>
      <CreateView visible={isShown} hide={toggle} reloadViews={reloadViews} modelBase={modelBase} />
      <Button style={{ border: 'none' }} onClick={showModal} icon={<PlusSquareOutlined />}>{t('addView')}</Button>
      <Modal
        closable={false}
        visible={visible}
        width={800}
        title={<div style={{ textAlign: 'right', alignItems: 'flex-end' }} ><Search allowClear={true} placeholder={t('searchView')} onSearch={(value) => filterTable(value)} style={{ width: 300 }} /></div>}
        onCancel={handleCancel}
        footer={[
          <Button key='selectView' type='primary' disabled={selectedRowKeys.length < 1} onClick={handleSelectView}>
            {t('selectView')}
          </Button>,
          <Button key='newView' type='primary' onClick={handleOk}>
            {t('createNewView')}
          </Button>,
          <Button key='back' onClick={handleCancel}>
            {t('cancel')}
          </Button>
        ]}
      >

        <div style={{ maxHeight: 350, overflowY: 'scroll' }}>
          <Table<IDataView> rowKey={row => row.id} columns={columns} dataSource={privateViews} pagination={false} size='small' showHeader={false} title={() =>
            <h4 style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.65)' }}>{t('private')}</h4>} rowSelection={{ ...rowSelection, type: 'radio' }} />
          <Table<IDataView> rowKey={row => row.id} columns={columns} dataSource={publicViews} pagination={false} size='small' showHeader={false} title={() =>
            <h4 style={{ fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.65)' }}>{t('public')}</h4>} rowSelection={{ ...rowSelection, type: 'radio' }} />
        </div>
      </Modal>
    </>
  );
};

const NewView = memo(WrappedComponent);
export default NewView;
