import React, { useState } from 'react';
import { Select, Button } from 'antd';
import Search from 'antd/lib/input/Search';
import TableConversations from './TableConversations';
import { useTranslation } from 'react-i18next';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import ResizePanel from 'react-resize-panel-ts';
import { ClasificationVm } from '../../common/types';

interface ISiderConversationResiProps {
  selectedKey: number | undefined,
  listItemClick: (key: number, entityId: number, service: string) => void,
  serviceFilterKeys: Array<number>;
  traySelected: number;
  clasification: ClasificationVm | undefined;
}
const SiderConversationResizable: React.FC<ISiderConversationResiProps> = ({
  selectedKey,
  listItemClick,
  traySelected,
  serviceFilterKeys,
  clasification
}: ISiderConversationResiProps) => {
  const { t } = useTranslation();
  const { Option } = Select;
  const [order, setOrderDesc] = useState<boolean>(true);
  const [textSearch, setTextSearch] = useState<string>('');
  const [groupProperty, setGroupBy] = useState<string>('entityFullName');

  const onOrderClick = (): void => setOrderDesc(!order);

  return <ResizePanel direction="e" containerClass="container-class-list" handleClass='container-class-list'>
    <div className="sidebar" >
      <div style={{ marginTop: 8, textAlign: 'left', height: 44, width: '100%' }}>
        <Search allowClear={true} placeholder={t('searchConversation')} onSearch={(value) => setTextSearch(value)} style={{ width: '60%' }} />
        <Select defaultValue='cliente' bordered={false} onChange={(value) => setGroupBy(value)}>
          <Option key='servicio' value='service'>{t('service')}</Option>
          <Option key='client' value='entityFullName'>{t('client')}</Option>
        </Select>
        <Button icon={order ? <ArrowUpOutlined /> : <ArrowDownOutlined />} type='text' onClick={onOrderClick}></Button>
      </div>
      <TableConversations clasification={clasification} filterOrderProps={{ serviceFilterKeys, textSearch, order, groupProperty, statusId: traySelected }} selectedKey={selectedKey} listItemClick={listItemClick} />
    </div>
  </ResizePanel>;
};

export default SiderConversationResizable;
