import React from 'react';
import { SelectEventHandler } from 'rc-menu/lib/interface';
import { ConversationsMenu } from './ConversationsMenu';
import ResizePanel from 'react-resize-panel-ts';

interface ConversationSiderResisableProps {
  collapsed: boolean,
  onSelect: SelectEventHandler,
  selectedKeys: string[],
  onCollapsed: () => void,
  isStatusChanged: boolean;
}

const ConversationSiderResisable: React.FC<ConversationSiderResisableProps> = ({
  collapsed, onSelect, selectedKeys, onCollapsed, isStatusChanged
}: ConversationSiderResisableProps) => {
  return <ResizePanel direction="e" containerClass="container-class" handleClass="container-class">
    <ConversationsMenu onSelect={onSelect} selectedKeys={selectedKeys} onCollapsed={onCollapsed} collapsed={collapsed} isStatusChanged={isStatusChanged} />
  </ResizePanel>;
};

export default ConversationSiderResisable;
