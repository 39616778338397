import { Space, Select, Tooltip, message, Input } from 'antd';
import '../../Assets/Styles/headerChat.less';
import {
  CheckCircleOutlined,
  StopOutlined,
  PauseCircleOutlined, TagOutlined, SearchOutlined
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConversationService from '../../Services/ConversationService';
import { StatusConversation } from '../../Constants/Enums';

interface HeaderChatProps {
  onSearch: (text: string) => void,
  selectedKey: number | undefined;
  clasificationChange: (selectedKey: number, clasificationId: number) => void;
  statusChanged: () => void;
}

const HeaderChat: React.FC<HeaderChatProps> = ({ onSearch, selectedKey, clasificationChange, statusChanged }: HeaderChatProps) => {
  const { Option } = Select;
  const { t } = useTranslation();

  const inputRef = React.useRef<any>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  useEffect(() => {
    setSearchValue('');
  }, [selectedKey]);

  const changeStatusConversation = async (statusId: number): Promise<void> => {
    if (selectedKey === undefined) {
      return;
    }
    const service = new ConversationService();
    const result = await service.updateStatus(statusId, selectedKey);
    if (result.IsSuccess) {
      message.success(t('updateSuccess'));
      statusChanged();
    } else {
      message.error(t('updateFailure'));
    }
  };

  const changeClasification = async (clasificationId: number): Promise<void> => {
    if (selectedKey === undefined) {
      return;
    }
    const service = new ConversationService();
    const result = await service.updateClasification(clasificationId, selectedKey);
    if (result.IsSuccess) {
      message.success(t('updateSuccess'));
    } else {
      message.error(t('updateFailure'));
    }
    if (clasificationChange) clasificationChange(selectedKey, clasificationId);
  };

  return <>
    <div style={{ display: 'flex', top: 0, float: 'right', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between' }}>
      <div style={{ fontSize: 20, marginTop: -8 }}>
        <Space size='large'>
          <Tooltip placement='top' title={t('searchMessage')}>
            <Input bordered={false} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} ref={inputRef}
              placeholder={t('searchMessage')} allowClear
              onPressEnter={(e) => onSearch(e.currentTarget.defaultValue)} style={{ paddingRight: 0, width: 200 }} />
            <SearchOutlined onClick={() => inputRef.current!.focus({ cursor: 'end' })} style={{ fontSize: 22, color: 'red', marginRight: 0, marginLeft: 0 }} />
          </Tooltip>
          <Tooltip placement='top' title={t('pauseConversation')}>
            <PauseCircleOutlined onClick={() => changeStatusConversation(StatusConversation.paused)} style={{ marginRight: '2%', cursor: 'pointer' }} />
          </Tooltip>
          <Tooltip placement='top' title={t('completeConversation')}>
            <CheckCircleOutlined onClick={() => changeStatusConversation(StatusConversation.complete)} style={{ marginRight: '0%', cursor: 'pointer' }} />
          </Tooltip>
          <div id='ant'>
            <Tooltip placement='top' title={t('tagConversation.tagAction')}>
              <Select bordered={false} style={{ marginRight: -18, marginLeft: -14 }} onSelect={(value: number) => { changeClasification(value); }} defaultValue={1} showSearch={false} aria-selected={'false'}>
                <Option value={1}>
                  <Tooltip placement='leftTop' title={t('tagConversation.creditRequest')}>
                    <TagOutlined style={{ fontSize: '22px', color: '#08c' }} rotate={45} />
                  </Tooltip>
                </Option>
                <Option value={2}>
                  <Tooltip placement='leftTop' title={t('tagConversation.consultInformation')}>
                    <TagOutlined style={{ fontSize: '22px', color: 'red' }} rotate={45} />
                  </Tooltip>
                </Option>
                <Option value={3}>
                  <Tooltip placement='leftTop' title={t('tagConversation.claim')}>
                    <TagOutlined style={{ fontSize: '22px', color: 'green' }} rotate={45} />
                  </Tooltip>
                </Option>
                <Option value={4}>
                  <Tooltip placement='leftTop' title={t('tagConversation.unfair')}>
                    <TagOutlined style={{ fontSize: '22px', color: 'black' }} rotate={45} />
                  </Tooltip>
                </Option>
              </Select>
            </Tooltip>
          </div>
          <Tooltip placement='top' title={t('spamConversation')}>
            <StopOutlined onClick={() => changeStatusConversation(StatusConversation.spam)} style={{ marginLeft: 0, marginRight: '0px', cursor: 'pointer' }} />
          </Tooltip>
        </Space>
      </div>
    </div >
  </>;
};

export default HeaderChat;
