/* eslint-disable no-unused-vars */
export enum ModuleId {
  Contacto = 1
}

export enum ShareTypeId {
  Privado = 1,
  Publico = 2,
}

export enum ModeForm {
  Read = 0,
  Create = 1,
  Edit = 2,
}

export enum StatusChat {
  sending,
  sent,
  received,
  read,
  error
}

export enum StatusConversation {
  active = 1,
  paused = 2,
  complete = 3,
  spam = 4,
  all = 0, // usado para los menú
}

export enum ConversationType {
  Received = 1, // mensaje recibido
  Sended = 2, // mensaje enviado
  Note = 3, // notas agregadas por el usuario a la conversación
  Title = 4, // encabezados de fecha
}
