import Banner from '../Banner';
import { Select, Tag } from 'antd';
import { CustomTagProps, ServiceVm } from '../../common/types';
import ButtonCreateContact from '../Contacts/ButtonCreateContact';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import ServicesService from '../../Services/ServicesService';

interface props {
  openContactForm: () => void;
  onServiceFilterChange: (values: number[]) => void;
}

const ChatRoomBanner: React.FC<props> = ({ onServiceFilterChange, openContactForm }: props) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<Array<ServiceVm>>([]);
  const [value, setValue] = useState<Array<number>>([]);
  useEffect(() => {
    const service = new ServicesService();
    const init = async (): Promise<void> => {
      const data = await service.getAll();
      setOptions(data);
      const ids = data.map((val) => val.id);
      setValue(ids);
      onServiceFilterChange(ids);
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { Option } = Select;

  const renderTag = ({ label, closable, onClose }: CustomTagProps): JSX.Element => {
    const onPreventMouseDown = (event: React.MouseEvent): void => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (<Tag
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}>
      {label}
    </Tag>);
  };

  const opt = options.map(d => <Option
    key={d.id} value={d.id} label={d.initials}
    title={d.initials}>{d.name}</Option>);

  const selectProps = {
    optionLabelProp: 'label',
    tagRender: renderTag,
    value,
    mode: 'multiple' as const,
    showArrow: true,
    style: { width: '100%' },
    onChange: (newValue: number[]) => {
      setValue(newValue);
      onServiceFilterChange(newValue);
    },
    showSearch: false
  };

  return <Banner>
    <div className='contacts-title' style={{ width: '20%' }}>
      <Select {...selectProps} placeholder={t('servicies')}>
        {opt}
      </Select>
    </div>
    <ButtonCreateContact openContactForm={openContactForm} />
  </Banner>;
};

export default ChatRoomBanner;
