import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Switch, Tooltip, Alert, Row, Col } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { FieldProps } from './FieldProps';
import { useTranslation } from 'react-i18next';
import EmailContactService from '../../Services/EmailContactService';
import { IContactEmail, ISaveContactEmail } from '../../common/types';

interface DynamicFieldSetProps {
  entityId: number;
  companyAccountId: string
  field: FieldProps;
}

const DynamicFieldSet: React.FC<DynamicFieldSetProps> = (props) => {
  interface MessageType {
    message: string;
    type: 'success' | 'info' | 'warning' | 'error';
  }

  const { t } = useTranslation();
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});
  const [data, setData] = useState<Array<IContactEmail>>();
  const [saving, setSaving] = useState<boolean>(false);
  const [message, setMessage] = useState<MessageType>({ message: '', type: 'info' });

  useEffect(() => {
    const emailServive = new EmailContactService();
    async function loadData (): Promise<void> {
      const result = await emailServive.getList(props.entityId, props.companyAccountId);
      setData(result);
    };
    loadData();
  }, [props.companyAccountId, props.entityId]);

  const addField = (): void => {
    const ids = data?.map(f => f.id) || [0, 1];
    const minId = Math.min(...ids);
    const newField: IContactEmail = {
      id: minId <= 0 ? minId - 1 : 0,
      entityId: props.entityId,
      companyAccountId: props.companyAccountId,
      emailAddress: '',
      isPrimary: false
    };
    const newData = data?.concat(newField);
    setData(newData);
  };

  const removeField = (field: IContactEmail): void => {
    const remainData = data?.filter(f => f !== field);
    setData(remainData);
  };

  const areThereErrors = (): boolean => {
    const errorCount = Object.values(validationErrors).filter(value => value !== '');
    return errorCount.length > 0;
  };

  const areThereMoreThanOnePrimary = (): boolean => {
    const primaryCount = data?.filter(value => value.isPrimary).length || 0;
    if (primaryCount > 1) setMessage({ message: t('onlyOnePrimaryIsAllow').replace('{0}', t('contactForm.' + props.field.internalName + '.placeholder').toLowerCase()), type: 'error' });
    return primaryCount > 1;
  };

  const saveData = async (): Promise<void> => {
    if (areThereErrors()) return;
    if (areThereMoreThanOnePrimary()) return;

    setSaving(true);
    const filterData = data?.filter(f => f.emailAddress.trim() !== '');
    const dataToSave: ISaveContactEmail = {
      emailVMs: filterData || []
    };
    try {
      const emailServive = new EmailContactService();
      await emailServive.save(dataToSave);
      setMessage({ message: t('dataSaved'), type: 'success' });
    } catch {
      setMessage({ message: 'Error during saving email', type: 'error' });
    };
    setSaving(false);
  };

  const isEmail = (value: string): boolean => {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValid = regexp.test(value);
    return isValid;
  };

  const handleValidation = (id: number, value: string): void => {
    const key = `${props.field.internalName}${id}`;
    const valErr = validationErrors;

    if (value === '') {
      valErr[key] = t('dynamicFieldSetMessage').replace('{0}', t('contactForm.' + props.field.internalName + '.placeholder').toLowerCase());
    } else {
      valErr[key] = isEmail(value) ? '' : 'No es correo válido';
    }
    setValidationErrors(valErr);
  };

  const onChange = (id: number) => (value: string) => {
    handleValidation(id, value);
    const changeData = data;
    const obj = changeData?.find(f => f.id === id);
    if (obj) { obj.emailAddress = value; }
    setData(changeData);
  };

  const onChangePrimary = (id: number) => (value: boolean) => {
    const changeData = data;
    const obj = changeData?.find(f => f.id === id);
    if (obj) { obj.isPrimary = value; }
    setData(changeData);
  };

  const checkError = (key: string): Promise<any> => {
    const msg = validationErrors[key]?.trim() || '';
    if (msg === '') {
      return Promise.resolve();
    }
    return Promise.reject(new Error(msg));
  };

  return (
    <Form name="dynamic_form_item">
      {data?.map((field, index) => (
        <Form.Item
          key={field.id}
        >
          <Form.Item
            key={`item_${field.id}`}
            name={`${props.field.internalName}${field.id}`}
            validateTrigger={['onChange', 'onBlur']}
            rules={[
              {
                validator: () => checkError(`${props.field.internalName}${field.id}`)
              }
            ]}
            noStyle
          >
            <Input placeholder={t('contactForm.' + props.field.internalName + '.placeholder')}
              type='email'
              onChange={e => onChange(field.id)(e.target.value)}
              defaultValue={field.emailAddress}
              addonAfter={
                <Tooltip title={t('isPrimary')}>
                  <Switch defaultChecked={field.isPrimary} onChange={e => onChangePrimary(field.id)(e)}></Switch>
                </Tooltip>}
            />
          </Form.Item>
          {
            data.length > 1
              ? <Button key={`rm_${field.id}`}
                icon={<MinusCircleOutlined style={{ color: '#f5222d' }} />}
                onClick={() => removeField(field)}
              />
              : null
          }
        </Form.Item>
      ))
      }
      <Form.Item>
        <Row gutter={4}>
          <Col>
            <Button
              type="dashed"
              onClick={addField}
              icon={<PlusOutlined />}
            >
              {t('add')} {props.field.isDynamic ? props.field.label : t('contactForm.' + props.field.internalName + '.placeholder').toLowerCase()}
            </Button>
          </Col>
          <Col>
            <Button type="primary" loading={saving} onClick={saveData}>
              {t('save')}
            </Button>
          </Col>
        </Row>
      </Form.Item>
      {
        message?.message.length > 0 &&
        <Form.Item>
          <Alert
            message={message?.message}
            type={message?.type}
            showIcon
            closable
            onClose={() => setMessage({ message: '', type: 'info' })}
          />
        </Form.Item>
      }
    </Form >
  );
};

export default DynamicFieldSet;
