
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import { Tag, Spin, Layout } from 'antd';
import WriteMessage from '../ChatRooms/WriteMessage';
import { ConversationType, StatusChat } from '../../Constants/Enums';
import React, { memo, useEffect, useState } from 'react';
import { ConversationDetailVm, newConversationDetailVm, ConversationDetailPage } from '../../common/types';
import ConversationDetailService from '../../Services/ConversationDetailService';
import { newIChatTypeTitle } from '../../Constants/OptionsSelect';
import { LoadingOutlined } from '@ant-design/icons';
import APIClient from '../../Services/APIClient';
import Highlighter from 'react-highlight-words';
import AttachmentMessage from '../ChatRooms/AttachmentMessage';

interface InfiniteScrollProps {
  selectedKey: number | undefined;
  searchText: string;
}

const WrappedComponent: React.FC<InfiniteScrollProps> = ({ searchText, selectedKey }: InfiniteScrollProps) => {
  const { Content } = Layout;
  const { t } = useTranslation();

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const [hasMore, setHasMore] = useState<boolean>(false);
  const [items, setItems] = useState<Array<ConversationDetailVm>>([]);
  const [filterItems, setFilterItems] = useState<Array<ConversationDetailVm>>([]);
  const [conversationDetailsPage, setConversationDetailPage] = useState<Array<ConversationDetailPage>>([]);

  const addItemLocal = (message: string): void => {
    const newItems = items.concat(newConversationDetailVm(Date.now(), message, items[items.length - 1].from, selectedKey || 0));
    setItems(newItems.sort(function (a: ConversationDetailVm, b: ConversationDetailVm) {
      return b.id - a.id;
    }));
  };

  const setTitles = (newItems: ConversationDetailVm[], conversationId: number | undefined): void => {
    for (let i = newItems.length - 1; i > 0; i = i - 1) {
      const item = newItems[i - 1];
      const lastItem = newItems[i];
      if (item.createdOn.format('DD-MM-yyyy') !== lastItem.createdOn.format('DD-MM-yyyy') && item.conversationType !== ConversationType.Title && item.conversationType !== ConversationType.Title) {
        newItems.splice(i, 0, newIChatTypeTitle(item.id, item.createdOn.format('DD-MM-yyyy'), item.createdOn, item.from, conversationId || 0));
      }
    }
  };

  const loadItems = async (sizePage: number): Promise<void> => {
    const service = new ConversationDetailService();
    const conversationDetail = conversationDetailsPage.find(p => p.conversationId === selectedKey);

    // petición para obtener nuevos registros de la conversación
    const chatData = await service.getByConversationId(selectedKey || 0, (conversationDetail?.currentPage || 1), sizePage);
    const { currentPage, pageCount, pageSize, rowCount, firstRowOnPage, lastRowOnPage } = chatData;
    // valido si es primera carga
    if (conversationDetail === undefined) {
      const newDetailPage = conversationDetailsPage.concat({ conversationId: selectedKey, currentPage, pageCount, pageSize, rowCount, firstRowOnPage, lastRowOnPage });
      setConversationDetailPage(newDetailPage);
      // valido si esta conversación tiene más registros pendientes por cargarse.
      pageCount <= 1 && setHasMore(false);
    } else {
      // de no ser primera carga actualizo el registro de tempDetailsPage
      const index = conversationDetailsPage.findIndex(p => p.conversationId === selectedKey);
      const tempDetailsPage = conversationDetailsPage;
      tempDetailsPage[index] = { conversationId: selectedKey, currentPage, pageCount, pageSize, rowCount, firstRowOnPage, lastRowOnPage };
      setConversationDetailPage(tempDetailsPage);
    }
    // obtengo la lista solamente de los elementos permanecientes a la conversación seleccionada
    const newItems = items.concat(chatData.results).filter(c => c.conversationId === selectedKey);
    // itero items de conversación para agregar elementos de tipo titulo para las fechas
    setTitles(newItems, selectedKey);
    // actualizo la lista de conversación, para no repetir registros filtro primero los items de la conversación actual
    setItems(items.filter((c) => c.conversationId !== selectedKey).concat(newItems));
  };

  const fetchMoreData = (conversationId: number | undefined, firstLoad: boolean, sizePage: number = 10): void => {
    if (conversationId === undefined) return;
    setHasMore(true);

    if (items.filter((p) => p.conversationId === conversationId && p.conversationType !== 4).length > 0 && searchText !== '') {
      const filtered = items.filter((p) => p.conversationId === conversationId && p.conversationType !== 4 && p.body.toUpperCase().includes(searchText.toUpperCase()));
      setTitles(filtered, conversationId);
      setFilterItems(filtered);
      setHasMore(false);
    }

    const conversationDetail = conversationDetailsPage.find(p => p.conversationId === conversationId);
    // valido si esta conversación tiene más registros pendientes por cargarse.
    if ((conversationDetail !== undefined && conversationDetail.currentPage > conversationDetail.pageCount) || (conversationDetail !== undefined && firstLoad && conversationDetail.currentPage >= conversationDetail.pageCount)) {
      setHasMore(false);
      return;
    }

    if (conversationDetail !== undefined && items.filter((p) => p.conversationId === conversationId && p.conversationType !== 4).length >= conversationDetail.currentPage * sizePage) {
      return;
    }

    loadItems(sizePage);
  };

  useEffect(() => {
    selectedKey !== undefined ? fetchMoreData(selectedKey, true) : setHasMore(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKey, searchText]);

  const sendMessage = async (message: string): Promise<void> => {
    // ToDo: remover valores en duro
    const dataMessage = {
      Direction: 'O',
      IsProcessed: false,
      AttachNumber: 0,
      MediaType: 'whatsapp',
      Body: ''
    };

    const apiClient = new APIClient();
    dataMessage.Body = message;
    const response = await apiClient.client.post(`Message?conversationId=${selectedKey}`, dataMessage);
    response.status === 200 && addItemLocal(message);
  };

  const drawFooter = (item: ConversationDetailVm): JSX.Element | undefined => {
    switch (item.chatStatus) {
      // reloj grey
      case StatusChat.sending: return <footer
        style={{
          top: 0,
          marginLeft: 2,
          float: 'right',
          color: 'grey',
          padding: 0,
          fontSize: 11,
          textAlign: 'end',
          right: 0,
          clear: 'right'
        }}>
        <span>⏱</span>
      </footer>;
      // un check grey
      case StatusChat.sent: return <footer
        style={{
          top: 0,
          marginLeft: 2,
          float: 'right',
          color: 'grey',
          padding: 0,
          fontSize: 11,
          textAlign: 'end',
          right: 0,
          clear: 'right'
        }}>
        <span>✓</span>
      </footer>;
      // doble check grey
      case StatusChat.received: return <footer
        style={{
          top: 0,
          marginLeft: 2,
          float: 'right',
          color: 'grey',
          padding: 0,
          fontSize: 11,
          textAlign: 'end',
          right: 0,
          clear: 'right'
        }}>
        <span>✓</span>
        <span style={{ marginLeft: -5 }}>✓</span>
      </footer>;
      // doble check blue
      case StatusChat.read: return <footer
        style={{
          top: 0,
          marginLeft: 2,
          float: 'right',
          color: 'rgb(28,177,241)',
          padding: 0,
          fontSize: 11,
          textAlign: 'end',
          right: 0,
          clear: 'right'
        }}>
        <span>✓</span>
        <span style={{ marginLeft: -5 }}>✓</span>
      </footer>;
      // equis rojo
      case StatusChat.error: return <footer
        style={{
          top: 0,
          marginLeft: 2,
          float: 'right',
          color: 'red',
          padding: 0,
          fontSize: 11,
          textAlign: 'end',
          right: 0,
          clear: 'right'
        }}>
        <span>error ⮾</span>
      </footer>;
    }
  };

  const drawDiv = (item: ConversationDetailVm, index: number): JSX.Element | undefined => {
    switch (item.conversationType) {
      case ConversationType.Received: return <div key={index}>
        <div>
          <p className='message-partner'>
            {item.mediaUrl !== '' && <AttachmentMessage item={item} />}
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText]}
              autoEscape={true}
              textToHighlight={item.body}
            />
          </p>
        </div>
        <span className='footerHourLeft'>{(item.createdOn.format('hh:mm A'))}</span>
      </div>;
      case ConversationType.Sended: return <div key={index}>
        <p className='message-box'>
          {item.mediaUrl !== '' && <AttachmentMessage item={item} />}
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape={true}
            textToHighlight={item.body}
          />
        </p>
        <span className='footerHourRight'>{`${(item.createdOn.format('hh:mm A'))}`}
          {drawFooter(item)}
        </span>
      </div>;
      case ConversationType.Title: return <div style={{ height: 50, top: 0, textAlign: 'center' }}>
        <Tag key={index} style={{ backgroundColor: 'rgba(191,191,191)', fontSize: 13, border: 'none' }}>
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape={true}
            textToHighlight={item.body}
          />
        </Tag>
      </div>;
      case ConversationType.Note: return <div key={index}>
        <p className='note-partner'>
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape={true}
            textToHighlight={item.body}
          />
        </p>
        <span className='footerHourRight'>  {`${item.from} ${item.createdOn.format('hh:mm A')}`}</span>
      </div>;
    }
  };

  const next = (): void => {
    const index = conversationDetailsPage.findIndex(p => p.conversationId === selectedKey);
    if (index >= 0) {
      const conversationDetailPage = conversationDetailsPage[index];
      if (conversationDetailPage.currentPage >= conversationDetailPage.pageCount) {
        setHasMore(false);
        return;
      }
      conversationDetailPage.currentPage += 1;
      conversationDetailsPage[index] = conversationDetailPage;
      setConversationDetailPage(conversationDetailsPage);
    }

    fetchMoreData(selectedKey, false);
  };
  return (
    <Layout key={selectedKey} style={{ backgroundColor: 'rgba(237, 234, 242)', maxHeight: '72vh', height: '71vh' }}>
      <Content>
        <div id='scrollableDiv'
          style={{
            maxHeight: '58vh',
            overflow: 'auto',
            display: 'flex',
            backgroundColor: 'rgba(237, 234, 242)',
            flexDirection: 'column-reverse'
          }}
        >
          <InfiniteScroll
            dataLength={items.filter((p) => p.conversationId === selectedKey).length}
            next={() => next()}
            style={{ display: 'flex', flexDirection: 'column-reverse' }} // To put endMessage and loader to the top.
            inverse={true}
            scrollableTarget='scrollableDiv'
            endMessage={<div style={{
              display: 'block',
              textAlign: 'center',
              alignContent: 'center'
            }}>
              <Tag style={{ backgroundColor: 'rgba(191,191,191)' }} visible={items.filter((p) => p.conversationId === selectedKey).length > 0}>{t('startConversation')}</Tag>
              <br />
              <Tag style={{ backgroundColor: 'rgba(191,191,191)' }}>
                {searchText !== ''
                  ? filterItems.filter(c => c.conversationId === selectedKey).slice(-1).pop()?.createdOn.format('DD-MM-YYYY')
                  : items.filter(c => c.conversationId === selectedKey).slice(-1).pop()?.createdOn.format('DD-MM-YYYY')}
              </Tag>
            </div>}
            hasMore={hasMore}
            loader={<Spin style={{ paddingTop: 5 }} indicator={antIcon} />}>
            {searchText !== ''
              ? filterItems.filter((p) => p.conversationId === selectedKey).map((item, index) => (
                drawDiv(item, index)
              ))
              : items.filter((p) => p.conversationId === selectedKey).map((item, index) => (
                drawDiv(item, index)
              ))
            }

          </InfiniteScroll>
        </div>
      </Content>
      <Layout.Footer style={{ padding: 0 }}>
        <WriteMessage selectedKey={selectedKey} sendMessage={sendMessage} />
      </Layout.Footer>
    </Layout>
  );
};

const InfiniteScrollReverse = memo(WrappedComponent);
export default InfiniteScrollReverse;
