import { useAuth } from 'oidc-react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import React from 'react';

const LogoutButton: React.FC = () => {
  const { t } = useTranslation();

  const auth = useAuth();
  if (auth && auth.userData) {
    return (
      <NavLink to="#"
        onClick={() => {
          auth.signOutRedirect();
        }}
      >
        {t('signOut')}
      </NavLink>
    );
  }
  return <div>Not logged in!</div>;
};

export default LogoutButton;
