import React, { FC as FunctionalComponent, useEffect, useState } from 'react';
import { Button, Empty, PageHeader, Row, Select, Space } from 'antd';
import Board from '../Components/Board/Board';
import OpportunitiesService from '../Services/OpportunitiesService';
import { IOpportunityModalData, IPipeline, IPipelineStage, IPipelineStat } from '../models/Opportunities';
import OpportunityDrawer from '../Components/Opportunity/OpportunityDrawer';
import Counter from '../Components/Opportunity/Counter';
import styled from 'styled-components';
import { DropResult } from 'react-beautiful-dnd';
import { useSnackbar } from 'notistack';
import { Result } from '../Services/Models/Result';
import ModalForm from '../Components/Opportunity/ModalForm';

const _service = new OpportunitiesService();

const Container = styled.div``;

const Opportunities: FunctionalComponent = () => {
  const { enqueueSnackbar } = useSnackbar();

  // UI states
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [loadingStats, setLoadingStats] = useState<boolean>(true);
  const [loadingKanban, setLoadingKanban] = useState<boolean>(true);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalSubmitting, setModalSubmitting] = useState<boolean>(false);
  const [modalData, setModalData] = useState<IOpportunityModalData>({} as IOpportunityModalData);

  // Data states
  const [pipeline, setPipeline] = useState<IPipeline>();
  const [stats, setStats] = useState<IPipelineStat[]>([]);
  const [stages, setStages] = useState<IPipelineStage[]>([]);
  const [totalPrefix, setTotalPrefix] = useState<string>('');

  // Functions
  const onToggleDrawer = (): void => setIsDrawerOpen(state => !state);

  const onDrawerClose = (): void => {
    // Update the state
    onToggleDrawer();
  };

  /**
   * Update the stages state after a transition has completed
   **/
  const updateState = (currentStageId: number, newStageId: number, sourceIndex: number): void => {
    // update state
    const stagesClone = [...stages];

    // Find the columns index
    const sourceColumnIndex = stagesClone.findIndex(stage => stage.id === currentStageId);
    const destColumnIndex = stagesClone.findIndex(stage => stage.id === newStageId);

    // The actual columns
    const sourceColumn = stagesClone[sourceColumnIndex];
    const destColumn = stagesClone[destColumnIndex];

    // The opportunities inside those columns
    const sourceItemsClone = [...Array.from(sourceColumn?.opportunities || [])];
    const destItemsClone = [...Array.from(destColumn?.opportunities || [])];

    // Get the moving element
    const [moved] = sourceItemsClone.splice(sourceIndex, 1);

    // Move it to the top position
    destItemsClone.splice(0, 0, moved);

    // Move the item to the destination index
    // destItemsClone.splice(destination.index, 0, moved);

    // Set the new values
    stagesClone[sourceColumnIndex] = { ...sourceColumn, opportunities: [...sourceItemsClone] };
    stagesClone[destColumnIndex] = { ...destColumn, opportunities: [...destItemsClone] };

    setStages(stagesClone);
  };

  const save = async (opportunityId: number, newStageId: number, formValues?: { [x: string]: unknown }): Promise<Result<number>> => {
    const result = await _service.saveTransition(opportunityId, newStageId, formValues);

    if (result && result.IsSuccess) {
      // enqueueSnackbar('Transición completada', { variant: 'success' });
      console.log('Transición completada');
    } else {
      enqueueSnackbar('Ha ocurrido un error', { variant: 'error' });
    }

    return result;
  };

  const handleModalOk = async (values: { [x: string]: unknown }): Promise<void> => {
    console.log('Submitting with', values);

    const { opportunityId, currentStageId, newStageId, sourceIndex } = modalData;
    setModalSubmitting(true);

    await save(opportunityId, newStageId, values);
    updateState(currentStageId, newStageId, sourceIndex);

    setModalSubmitting(true);
    setModalVisible(false);
  };

  /**
   * Define the logic for the transition
   */
  const onDragEnd = async (result: DropResult): Promise<void> => {
    const { destination, source, draggableId } = result;

    // Dragged outside a draggable component
    if (!destination) {
      return;
    }

    const opportunityId = +draggableId;
    const currentStageId = +source.droppableId;
    const newStageId = +destination.droppableId;

    console.log(`Moving opportunity ${opportunityId} from ${currentStageId} to ${newStageId}`);

    // Dropping at different columns
    if (currentStageId !== newStageId) {
      const flow = pipeline?.flow;

      const transition = flow?.find(item => item.from === currentStageId && item.to === newStageId);
      if (!transition) {
        enqueueSnackbar('Can\'t transition to the desired stage', { variant: 'error' });
        return;
      }

      if (transition.askForData) {
        console.log('User needs to fill a form');

        // Show a Modal asking for comments or data
        setModalData({ opportunityId, currentStageId, newStageId, sourceIndex: +source.index });

        setModalSubmitting(false);
        setModalVisible(true);
      } else {
        await save(opportunityId, newStageId);
        updateState(currentStageId, newStageId, +source.index);
      }
    }
  };

  const onCardClick = (): void => {
    setIsDrawerOpen(true);
  };

  useEffect(() => {
    const getPipelineData = async () : Promise<void> => {
      try {
        const user = _service.getUser();

        if (!user) {
          console.log('No user found!');
          return;
        }

        // Get the default pipeline for the user
        const pipeline = await _service.getDefaultUserPipeline();
        if (!pipeline) {
          console.log('No existe un Pipeline por defecto para el usuario');
          setLoadingKanban(false);
          return;
        }

        const stages = pipeline.stages.filter((stage: IPipelineStage) => !stage.isInternal);
        const currency = pipeline.currency;

        setPipeline(pipeline);
        setStages(stages);
        setTotalPrefix(currency.symbol);

        // Get the Pipeline Stats
        const stats = await _service.getPipelineStats(pipeline.id);
        setStats(stats);

        console.log('STATS', pipeline);
      } catch (error) {
        // error
      }

      setLoadingKanban(false);
      setLoadingStats(false);
    };

    getPipelineData();
  }, []);

  return (
    <>
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Oportunidades"
        tags={
          <Space>
            <Select
              style={{ width: 200 }}
              defaultValue="1"
            >
              <Select.Option value="1">Televentas NI</Select.Option>
              <Select.Option value="2">Servicio al Cliente</Select.Option>
              <Select.Option value="3">Mesa de Cambio</Select.Option>
            </Select>

            <Select
              style={{ width: 200 }}
              defaultValue="1"
            >
              <Select.Option value="1">Sales Pipeline</Select.Option>
            </Select>
          </Space>
        }
        extra={[
          <Button key='create-op' onClick={onToggleDrawer} type="primary">Crear Oportunidad</Button>
        ]}
      />

      <Container className="generic-container crm-container">
        {stats && !loadingStats && (
          <Row gutter={[16, 16]}>
            {stats.map((stat: IPipelineStat, i: number) => <Counter key={i} title={stat.label} value={ stat.value } prefix={stat.prefix} suffix={stat.suffix} />)}
          </Row>
        )}

        {!stats && loadingStats && (
          <div>Cargando...</div>
        )}

        {(() => {
          if (pipeline && !loadingKanban) {
            if (!pipeline.flow.length) {
              return <Empty description='No existe un flujo configurado para el Pipeline' />;
            } else {
              return <Board stages={stages} totalPrefix={totalPrefix} OnDragEnd={onDragEnd} OnCardClick={onCardClick} />;
            }
          }
        })()}

        {!pipeline && loadingKanban && (
          <div>Cargando...</div>
        )}

        {!pipeline && !loadingKanban && (
          <Empty description='No se ha encontrado un Pipeline por defecto para el usuario' />
        )}
      </Container>

      <OpportunityDrawer
        isOpen={isDrawerOpen}
        onClose={onDrawerClose}
      />

      <ModalForm
        visible={modalVisible}
        loading={modalSubmitting}
        data={modalData}
        onSave={handleModalOk}
        onCancel={() => {
          setModalVisible(false);
        }}
      />
    </>
  );
};

export default Opportunities;
