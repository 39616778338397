import APIClient from './APIClient';
import moment from 'moment';
import {
  ConversationDetailVm,
  ConversationDetailPagedResult
} from '../common/types';

export default class ConversationDetailService extends APIClient {
  async getByConversationId (
    conversationId: number,
    pageNumber: number,
    pageSize: number
  ): Promise<ConversationDetailPagedResult> {
    const { data } = await this.get<ConversationDetailPagedResult>(
      `ConversationDetail/${conversationId}?pageSize=${pageSize}&pageNumber=${pageNumber}`
    );
    const result = data.results.map(function (item: ConversationDetailVm) {
      return {
        id: item.id,
        body: item.body,
        conversationType: item.conversationType,
        createdOn: moment(item.createdOn),
        from: item.from,
        conversationId: item.conversationId,
        statusChat: item.chatStatus,
        mediaUrl: item.mediaUrl,
        mediaContentType: item.mediaContentType
      };
    });

    data.results = result;

    return data;
  }
}
