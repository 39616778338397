import React, { FC as FunctionalComponent } from 'react';
import { Col, Statistic, StatisticProps } from 'antd';
import { Card } from '../../styled/Card';

const Counter: FunctionalComponent<StatisticProps> = ({ ...props }) => {
  // const [loading, setLoading] = useState(true);

  return (
    <Col xs={24} sm={12} md={6} lg={6} xl={6}>
      <Card>
        <Statistic { ...props } style={{ color: 'red' }} />
      </Card>
    </Col>
  );
};

export default Counter;
