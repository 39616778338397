import { Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { SelectEventHandler } from 'rc-menu/lib/interface';
import {
  UserOutlined, MenuUnfoldOutlined,
  MenuFoldOutlined, FolderOutlined, PauseOutlined,
  CheckCircleOutlined, StopOutlined
} from '@ant-design/icons';
import DrawerContactForm from '../DrawerContactForm';
import { ModeForm, StatusConversation } from '../../Constants/Enums';
import { useTranslation } from 'react-i18next';
import ConversationService from '../../Services/ConversationService';

interface props {
  onSelect: SelectEventHandler,
  selectedKeys: string[],
  onCollapsed: () => void,
  collapsed: boolean,
  isStatusChanged: boolean;
}

interface trayCounter {
  allConversation: number,
  activeConversation: number,
  pausedConversation: number,
  completeConversation: number,
  spamConversation: number,
}

export const ConversationsMenu: React.FC<props> = ({ onSelect, selectedKeys, onCollapsed, collapsed, isStatusChanged }: props) => {
  const [isContactFormOpen, setIsContactFormOpen] = useState<boolean>(false);
  const [counter, setCounter] = useState<trayCounter>({} as trayCounter);
  const closeContactForm = (): void => setIsContactFormOpen(false);
  const { t } = useTranslation();

  useEffect(() => {
    const init = async (): Promise<void> => {
      const service = new ConversationService();
      const result = await service.getCounterStatus();
      if (result) {
        const total = result
          .map(item => item.count)
          .reduce((prev, curr) => prev + curr, 0);

        const active = result.filter((item) => item.statusId === StatusConversation.active)[0];
        const pause = result.filter((item) => item.statusId === StatusConversation.paused)[0];
        const spam = result.filter((item) => item.statusId === StatusConversation.spam)[0];
        const complete = result.filter((item) => item.statusId === StatusConversation.complete)[0];

        const a = {
          allConversation: total,
          pausedConversation: pause?.count,
          completeConversation: complete?.count,
          spamConversation: spam?.count,
          activeConversation: active?.count
        } as trayCounter;
        setCounter(a);
      }
    };
    init();
  }, [isStatusChanged]);

  return <>
    <Menu style={{ border: 'none', background: '#f7f7f7' }} mode='inline' onSelect={onSelect} selectedKeys={selectedKeys}>
      <Menu.Item key={-1} onClick={onCollapsed} icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}>
        {t('trays')}
      </Menu.Item>
      <Menu.Divider style={{ marginBottom: 0, paddingBottom: 0, marginTop: 0, paddingTop: 0 }} />
      <Menu.Item key={StatusConversation.all} icon={<FolderOutlined />}>
        {`${t('allConversations')} ${counter.allConversation || 0}`}
      </Menu.Item>
      <Menu.Item key={StatusConversation.active} icon={<UserOutlined />}>
        {`${t('myConversations')} ${counter.activeConversation || 0}`}
      </Menu.Item>
      <Menu.Item key={StatusConversation.paused} icon={<PauseOutlined />}>
        {`${t('pausedConversations')} ${counter.pausedConversation || 0}`}
      </Menu.Item>
      <Menu.Item key={StatusConversation.complete} icon={<CheckCircleOutlined />}>
        {`${t('finishedConversations')} ${counter.completeConversation || 0}`}
      </Menu.Item>
      <Menu.Item key={StatusConversation.spam} icon={<StopOutlined />}>
        {`${t('spamConversations')} ${counter.spamConversation || 0}`}
      </Menu.Item>
    </Menu>
    <DrawerContactForm
      id={2}
      modeFormId={ModeForm.Edit}
      isOpen={isContactFormOpen}
      onClose={closeContactForm}
    />
  </>;
};
