import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth } from 'oidc-react';
import { useTranslation } from 'react-i18next';
import LogoutButton from './Authorization/LogoutButton';
import { Col, Dropdown, Menu, Row } from 'antd';
import { BellFilled, DownOutlined } from '@ant-design/icons';
import Avatar from 'antd/lib/avatar/avatar';

const UserNavMenu: React.FC = () => {
  const auth = useAuth();
  const { t } = useTranslation();

  const menu = (
    <Menu className='nav-user-menu'>
      <Menu.Item>
        <Row className='user-info'>
          <Col md='12'>
            <h4>{auth?.userData?.profile.name}</h4>
            <p>
              <small>{auth?.userData?.profile.email}</small>
            </p>
            <NavLink to='#'>{t('profileAndPreferences')}</NavLink>
          </Col>
        </Row>
      </Menu.Item>
      <Menu.Item>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://www.aliyun.com'
        >
          {t('accountAndBilling')}
        </a>
      </Menu.Item>
      <Menu.Item>
        <Row>
          <Col className='bottom-section'>
            <LogoutButton></LogoutButton>
            <NavLink to='#'>{t('privacyPolicy')}</NavLink>
          </Col>
        </Row>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className='crm-navbar-sec'>
      <BellFilled style={{ fontSize: '2em', color: 'white' }} />
      &nbsp; &nbsp;
      <Dropdown overlay={menu} placement='bottomLeft' arrow>
        <a
          href='/#'
          className='ant-dropdown-link'
          onClick={(e) => e.preventDefault()}
        >
          <div className='nav-user-dropdown'>
            <Avatar
              className='initials-avatar'
            >
              LS
            </Avatar>
            &nbsp;
            <DownOutlined />
          </div>
        </a>
      </Dropdown>
    </div>
  );
};

export default UserNavMenu;
