import React, { ReactNode, useState } from 'react';
import { Checkbox, Col, DatePicker, Form, Input, InputNumber, Radio, Row, Select } from 'antd';
import '../../Assets/Styles/activityCard.less';
import '../../Assets/Styles/componentFactory.less';
import { useTranslation } from 'react-i18next';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { FieldProps } from './FieldProps';
import { ValidationErrors } from '../../Services/Models/ValidationErrors';
import AuditDetail from '../Audits/AuditDetail';
import { ModuleId } from '../../Constants/Enums';
import FormControlOptions from './FormControlOptions';
import { FormControlOptionsFactory } from './FormControlOptionsFactory';

const icons = require('@ant-design/icons');
const { Option } = Select;

interface ComponentFactoryProps {
  id: number;
  companyAccountId: string;
  field: FieldProps;
  register: UseFormRegister<FieldValues>;
  onChange: (name: string) => (value: unknown) => void;
  validationErrors?: ValidationErrors;
}

const getIcon = (type: string): JSX.Element => {
  const Component = icons[type];
  return <Component />;
};

const ComponentFactory: React.FC<ComponentFactoryProps> = ({ id, companyAccountId, field, register, onChange, validationErrors }: ComponentFactoryProps) => {
  const { t } = useTranslation();
  const { controlType, internalName, isRequired, label, options, isEditable, icon } = field;
  const isDisabled = !isEditable;
  const containsError = validationErrors && validationErrors.errors && validationErrors.errors[internalName];
  const errorExcerpt = validationErrors && validationErrors.errors && validationErrors.errors[internalName]?.join('\n');
  const [optionsVisible, setOptionsVisible] = useState(false);

  const handleOptionVisible = (visible: boolean): void => {
    if (id === undefined) {
      setOptionsVisible(false);
    } else {
      setOptionsVisible(visible);
    }
  };

  const FormControlOptionsItemsArray: Array<ReactNode> = FormControlOptionsFactory(id, companyAccountId, field, validationErrors);
  switch (controlType) {
    case 'single-line-text':
      return (<Col span={12} onMouseEnter={() => handleOptionVisible(true)} onMouseLeave={() => handleOptionVisible(false)}>
        <Form.Item>
          <Form.Item
            name={internalName}
            required={isRequired}
            key={internalName}
            validateStatus={containsError ? 'error' : 'success'}
            help={containsError && errorExcerpt}
            noStyle
          >
            <Input
              prefix={icon && getIcon(icon)}
              placeholder={field.isDynamic ? label : t('contactForm.' + internalName + '.placeholder')}
              {...register(`${internalName}`, { required: isRequired })}
              onChange={e => onChange(internalName)(e.target.value)}
              disabled={isDisabled}
            />
          </Form.Item>
          {optionsVisible &&
            (FormControlOptionsItemsArray.length === 1
              ? FormControlOptionsItemsArray
              : <FormControlOptions key={`fco_${internalName}`} menuItems={FormControlOptionsItemsArray} />
            )
          }
        </Form.Item>
      </Col >
      );
    case 'multi-line-text':
      return (
        <Col span={24} onMouseEnter={() => handleOptionVisible(true)} onMouseLeave={() => handleOptionVisible(false)}>
          <Form.Item>
            <Form.Item
              name={internalName}
              required={isRequired}
              key={internalName}
              validateStatus={containsError ? 'error' : 'success'}
              help={containsError && errorExcerpt}
              noStyle
            >
              <Input.TextArea
                placeholder={label}
                disabled={isDisabled}
                {...register(`${internalName}`, { required: isRequired })}
                onChange={e => onChange(internalName)(e.target.value)}
              />
            </Form.Item>
            {optionsVisible && <AuditDetail key={`audit_${internalName}`} fieldId={field.fieldId} moduleId={ModuleId.Contacto} itemId={id} />}
          </Form.Item>
        </Col>
      );
    case 'single-checkbox': {
      const { ref, name } = register(`${internalName}`, { required: isRequired });
      return (
        <Col span={24} onMouseEnter={() => handleOptionVisible(true)} onMouseLeave={() => handleOptionVisible(false)}>
          <Form.Item>
            <Form.Item
              name={name}
              key={internalName}
              required={isRequired}
              validateStatus={containsError ? 'error' : 'success'}
              help={containsError && errorExcerpt}
              noStyle
            >
              <Checkbox
                ref={ref}
                onChange={e => onChange(name)(e.target.checked || false)}
                name={name}
                disabled={isDisabled}
              >{label}</Checkbox>
            </Form.Item>
            {optionsVisible && <AuditDetail key={`audit_${internalName}`} fieldId={field.fieldId} moduleId={ModuleId.Contacto} itemId={id} />}
          </Form.Item>
        </Col>
      );
    }
    case 'multi-checkbox': {
      const { ref, name } = register(`${internalName}`, { required: isRequired });
      return (
        <Col span={24} onMouseEnter={() => handleOptionVisible(true)} onMouseLeave={() => handleOptionVisible(false)}>
          <Form.Item>
            <Form.Item
              required={isRequired}
              name={internalName}
              validateStatus={containsError ? 'error' : 'success'}
              help={containsError && errorExcerpt}
              noStyle
            >
              <Checkbox.Group
                onChange={onChange(internalName)}
                ref={ref}
                name={name}
                disabled={isDisabled}
              >
                <Row>
                  {options?.map(option => (
                    <Col span={8} key={`chk-${option.value}`}>
                      <Checkbox
                        value={option.value}
                        style={{ lineHeight: '32px' }}>
                        {option.label}
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Form.Item>
            {optionsVisible && <AuditDetail key={`audit_${internalName}`} fieldId={field.fieldId} moduleId={ModuleId.Contacto} itemId={id} />}
          </Form.Item>
        </Col>
      );
    }
    case 'dropdown-select': {
      const { ref, onBlur, name } = register(`${internalName}`, { required: isRequired });
      return (
        <Col span={12} onMouseEnter={() => handleOptionVisible(true)} onMouseLeave={() => handleOptionVisible(false)}>
          <Form.Item>
            <Form.Item
              required={isRequired}
              name={name}
              key={internalName}
              validateStatus={containsError ? 'error' : 'success'}
              help={containsError && errorExcerpt}
              noStyle
            >
              <Select
                suffixIcon={icon && getIcon(icon)}
                placeholder={t('contactForm.' + internalName + '.placeholder')}
                onChange={onChange(internalName)}
                allowClear
                ref={ref}
                onBlur={onBlur}
                disabled={isDisabled}
              >
                {options?.map((option) => {
                  return (
                    <Option
                      value={option.value}
                      key={`radio-${option.value}`}
                    >{option.label}</Option>
                  );
                })}
              </Select>
            </Form.Item>
            {optionsVisible && <AuditDetail key={`audit_${internalName}`} fieldId={field.fieldId} moduleId={ModuleId.Contacto} itemId={id} />}
          </Form.Item>
        </Col>
      );
    }
    case 'radio-select': {
      return (
        <Col span={12} onMouseEnter={() => handleOptionVisible(true)} onMouseLeave={() => handleOptionVisible(false)}>
          <Form.Item>
            <Form.Item
              required={isRequired}
              name={internalName}
              label={label}
              validateStatus={containsError ? 'error' : 'success'}
              help={containsError && errorExcerpt}
              noStyle
            >
              <Radio.Group
                {...register(`${internalName}`, { required: isRequired })}
                disabled={isDisabled}
                onChange={onChange(internalName)}
              >
                {
                  options?.map(option => (
                    <Radio
                      key={`radio-${option.value}`}
                      value={option.value}
                    >{option.label}</Radio>
                  ))
                }
              </Radio.Group>
            </Form.Item>
            {optionsVisible && <AuditDetail key={`audit_${internalName}`} fieldId={field.fieldId} moduleId={ModuleId.Contacto} itemId={id} />}
          </Form.Item>
        </Col>);
    }
    case 'date-picker': {
      const { ref, onBlur, name } = register(`${internalName}`, { required: isRequired });
      return (
        <Col span={12} onMouseEnter={() => handleOptionVisible(true)} onMouseLeave={() => handleOptionVisible(false)}>
          <Form.Item>
            <Form.Item
              name={internalName}
              required={isRequired}
              validateStatus={containsError ? 'error' : 'success'}
              help={containsError && errorExcerpt}
              noStyle
            >
              <DatePicker
                style={{ width: '100%' }}
                placeholder={label}
                onChange={e => onChange(name)(e?.toDate().toISOString())}
                ref={ref}
                onBlur={onBlur}
                name={name}
                disabled={isDisabled}
              />
            </Form.Item>
            {optionsVisible && <AuditDetail key={`audit_${internalName}`} fieldId={field.fieldId} moduleId={ModuleId.Contacto} itemId={id} />}
          </Form.Item>
        </Col>);
    }
    case 'number': {
      const { ref, onBlur, name } = register(`${internalName}`, { required: isRequired });
      return (
        <Col span={12} onMouseEnter={() => handleOptionVisible(true)} onMouseLeave={() => handleOptionVisible(false)}>
          <Form.Item>
            <Form.Item
              required={isRequired}
              name={internalName}
              validateStatus={containsError ? 'error' : 'success'}
              help={containsError && errorExcerpt}
              noStyle
            >
              <InputNumber
                placeholder={label}
                stringMode={true}
                onChange={onChange(name)}
                ref={ref}
                onBlur={onBlur}
                name={name}
                disabled={isDisabled}
              />
            </Form.Item>
            {optionsVisible && <AuditDetail key={`audit_${internalName}`} fieldId={field.fieldId} moduleId={ModuleId.Contacto} itemId={id} />}
          </Form.Item>
        </Col>);
    }

    default:
      return (
        <Col span={12}>
          Not supported yet 😥
        </Col>
      );
  }
};

export default ComponentFactory;
