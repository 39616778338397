import React, { FC as FunctionalComponent, useEffect, useState } from 'react';
import { Modal /*, Form, Input */ } from 'antd';
import { IOpportunityModalData } from '../../models/Opportunities';
import OpportunitiesService from '../../Services/OpportunitiesService';
import { FormSchema } from '../DynamicForms/FormSchema';
import DynamicForm from '../DynamicForms';
import { ValidationErrors } from '../../Services/Models/ValidationErrors';
import { Result } from '../../Services/Models/Result';

type Props = {
    visible: boolean;
    loading: boolean;
    data: IOpportunityModalData | undefined;
    onSave: (data: { [x: string]: unknown }) => void;
    onCancel: () => void;
}

const _opportunityService = new OpportunitiesService();

const ModalForm: FunctionalComponent<Props> = ({ visible, loading, data, onSave, onCancel }) => {
  const [formSchema, setFormSchema] = useState<FormSchema>({} as FormSchema);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({} as ValidationErrors);
  const [serverError, setServerError] = useState({} as Result<number>);
  const [isLoading, setLoading] = useState<boolean>(true);

  // const [form] = Form.useForm();

  useEffect(() => {
    const getData = async (): Promise<void> => {
      const form = await _opportunityService.getCloseForm();
      console.log(form);
      setFormSchema(form);

      setLoading(false);
    };

    getData();
  }, []);

  const onSubmit = async (formData: { [x: string]: unknown }): Promise<void> => {
    const { dynamicData } = formData;
    console.log('Trying to save with data ', dynamicData);

    onSave(formData);
    setValidationErrors({} as ValidationErrors);
    setServerError({} as Result<number>);
  };

  return (
      <Modal
        title="Completa para realizar el cambio de etapa"
        visible={visible}
        onCancel={onCancel}
        confirmLoading={loading}
        footer={[]}
      >
        {isLoading && <div>Cargando...</div>}
        {!isLoading && <DynamicForm
          onSubmit={onSubmit}
          onReset={onCancel}
          submitButtonText={'save'}
          cancelButtonText={'cancel'}
          validationErrors={validationErrors}
          serverErrors={serverError}
          schema={formSchema}
        />}
      </Modal>
  );
};

export default ModalForm;
