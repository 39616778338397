import { IDataView, DataType, ConversationDetailVm } from '../common/types';
import { ConversationType } from './Enums';
import moment from 'moment';

export const options = [
  { value: 'value1', label: 'label1' },
  { value: 'value2', label: 'label2' }
];

export const newViewTab = (): IDataView => {
  return {
    id: 0,
    companyAccountId: '',
    moduleId: 0,
    name: '',
    owner: '',
    shareTypeId: 0,
    sqlViewName: '',
    isDefault: 0,
    isPinned: false
  };
};

export const newIChatTypeTitle = (conversationKey: number, body: string, date: moment.Moment, source: string, conversationId: number): ConversationDetailVm => {
  return {
    body: body,
    id: conversationKey,
    createdOn: date,
    conversationType: ConversationType.Title,
    from: source,
    conversationId: conversationId
  };
};

export const newDataType = (
  index: number,
  label: string,
  key: number,
  dataViewId: undefined | number,
  fieldId: number,
  companyAccountId: string,
  position: number
): DataType => {
  return {
    index: index,
    label: label,
    key: key,
    fieldId: fieldId,
    companyAccountId: companyAccountId,
    dataViewId: dataViewId,
    position: position
  };
};
