import { Checkbox, Table } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import Search from 'antd/lib/input/Search';
import { VisibleFieldProps, VisibleFieldDataType } from '../../common/types';
import { useAuth } from 'oidc-react';
import { useTranslation } from 'react-i18next';
import APIClient from '../../Services/APIClient';
import { ModuleId } from '../../Constants/Enums';
import { ColumnType } from 'antd/lib/table';

const VisibleFieldTable: React.FC<VisibleFieldProps> = ({ dataViewId, onChange }: VisibleFieldProps) => {
  const { t } = useTranslation();

  const auth = useAuth();
  const [data, setData] = useState<Array<VisibleFieldDataType>>([]);
  const [availableFields, setAvailableFields] = useState<Array<VisibleFieldDataType>>([]);

  const renderCheckBox = (value: boolean, record: VisibleFieldDataType, index: number): ReactElement =>
    <Checkbox
      defaultChecked={value}
      onChange={(e) => onChange(e, record, index)} />
    ;

  const columns: Array<ColumnType<VisibleFieldDataType>> = [
    {
      key: 'isVisible',
      title: t('visible'),
      dataIndex: 'isVisible',
      width: 30,
      render: (value: boolean, record: VisibleFieldDataType, index: number) => {
        return renderCheckBox(value, record, index);
      }
    },
    {
      title: t('field'),
      dataIndex: 'label'
    }
  ];

  const filterTable = (filterText: string): void => {
    if (filterText.length > 0) {
      const result = data.filter((view) => view.label.toLowerCase().indexOf(filterText.toLowerCase()) > -1);
      setAvailableFields(result);
    } else {
      setAvailableFields(data);
    }
  };

  const title = (): ReactElement => {
    return <Search allowClear={true} placeholder={t('searchView')} onSearch={(value) => filterTable(value)} style={{ marginLeft: -16, width: 275 }} />;
  };

  useEffect(() => {
    async function loadData (): Promise<void> {
      try {
        if (!!auth?.userData?.access_token && dataViewId !== undefined) {
          const apiClient = new APIClient();
          const result = await apiClient.client.get(`/VisibleField/GetFields?dataViewId=${dataViewId}&moduleId=${ModuleId.Contacto}`);
          setData(result.data);
          setAvailableFields(result.data);
        }
      } catch (err) { console.error(err); }
    }
    loadData();
  }, [auth?.userData?.access_token, dataViewId]);

  return (<div style={{ maxHeight: 350, maxWidth: 450, overflowY: 'scroll' }} >
    <Table
      pagination={false}
      dataSource={availableFields}
      title={title}
      columns={columns}
      rowKey="id"
    />
  </div>);
};

export default VisibleFieldTable;
