import React, { ReactElement, useEffect, useState } from 'react';
import { Table } from 'antd';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

const SortableItem = SortableElement((props: any) => <tr {...props} />);
const SortableList = SortableContainer((props: any) => <tbody {...props} />);

interface SortableProps {
  dataViewId: number | undefined,
  data: Array<any>,
  columns: Array<any>,
  onSortEnd: Function
}

const SortableTable: React.FC<SortableProps> = (props: SortableProps) => {
  const [dataSource, setDataSource] = useState(props.data);
  const [columns] = useState(props.columns);

  useEffect(() => {
    setDataSource(props.data);
  }, [props.data]);

  const DraggableContainer = (properties: any): ReactElement => (
    <SortableList
      useDragHandle
      disableAutoscroll
      helperClass='row-dragging'
      onSortEnd={props.onSortEnd}
      {...properties}
    />
  );

  const DraggableBodyRow = ({ ...restProps }): ReactElement => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(x => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <div style={{ maxHeight: 350, maxWidth: 300, overflowY: 'scroll' }}>
      <Table
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        rowKey='index'
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow
          }
        }}
      />
    </div>
  );
};

export default SortableTable;
