import { IContactEmail } from '../common/types';
import APIClient from './APIClient';
import { Result } from './Models/Result';

export default class EmailContactService extends APIClient {
  async getList (entityId: number, companyAccountId: string): Promise<Array<IContactEmail>> {
    const { data } = await this.get<Array<IContactEmail>>(`email/${entityId}/${companyAccountId}`);
    return data;
  }

  async save (email: unknown): Promise<Result<number>> {
    const { data } = await this.post<Result<number>>('email', email);
    return data;
  }
}
