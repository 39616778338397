import { Button } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
// import OverlayMenuContact from './MenuCreateContact';
import { useTranslation } from 'react-i18next';
import React from 'react';

interface props {
  openContactForm: () => void
}

const ButtonCreateContact: React.FC<props> = ({ openContactForm }: props) => {
  const { t } = useTranslation();

  return <div className='options'>
    <Button onClick={openContactForm}>
      <UserAddOutlined />
      {t('createContact')}
    </Button>
  </div>;
};

export default ButtonCreateContact;
