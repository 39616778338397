import { Input, Select, Tabs, Button, Modal, Popover, Upload, message, List, Form, Row, Col } from 'antd';
import React, { BaseSyntheticEvent, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { SendOutlined, PaperClipOutlined, SmileOutlined, LoadingOutlined, PlusCircleOutlined } from '@ant-design/icons';

import { IconsTable } from './IconsTable';
import { EditSolid } from '../../Assets/Icons/IconsSolid';
import { UploadFile } from 'antd/lib/upload/interface';

interface WriteMessageProps {
  selectedKey: number | undefined;
  sendMessage: (message: string) => Promise<void>;
}

const WriteMessage: React.FC<WriteMessageProps> = ({ selectedKey, sendMessage }: WriteMessageProps) => {
  const { TabPane } = Tabs;
  const { t } = useTranslation();
  const inputRef = React.useRef<any>(null);

  const { Search } = Input;
  const [form] = Form.useForm();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  interface infoFile {
    file: UploadFile,
    fileList: Array<UploadFile>
  }
  const props = {
    showUploadList: false,
    name: 'file',
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    headers: {
      authorization: 'authorization-text'
    },
    onChange (info: infoFile) {
      setLoading(true);

      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        setLoading(false);
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        setLoading(false);
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  const handleOk = (): void => {
    setIsModalVisible(false);
  };

  const handleCancel = (): void => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    console.log('selectedKey: ', selectedKey);
  }, [selectedKey]);

  const onIconClick = (e: BaseSyntheticEvent): void => {
    const msj = document.getElementById('message') as HTMLInputElement;
    if (msj) {
      const message: string = form.getFieldValue('message') ?? '';
      const newMessage = message.substring(0, msj.selectionEnd ?? 0) + e.target.innerText + message.substring(msj.selectionEnd ?? 0, message.length);
      form.setFieldsValue({ message: newMessage });
    }
  };

  const searchTemplate = (
    <>
      <div style={{ width: '100%' }} >
        <Button type='text'>
          <PlusCircleOutlined style={{ color: 'red' }} />{t('new')}
        </Button>
        <Button type='text'>
          <EditSolid style={{ color: 'red' }} />{t('edit')}
        </Button>
      </div>
      <Search placeholder={t('search')} />
    </>
  );
  const searchIcon = (
    <Search placeholder={t('searchIcon')} />
  );

  const data = [
    {
      title: 'Ant Design Title 1'
    },
    {
      title: 'Ant Design Title 2'
    },
    {
      title: 'Ant Design Title 3'
    },
    {
      title: 'Ant Design Title 4'
    }
  ];

  const templateList = (<List
    size='small'
    itemLayout='horizontal'
    dataSource={data}
    renderItem={item => (
      <List.Item>
        <List.Item.Meta
          title={item.title}
          description='Ant Design, a design language for background applications, is refined by Ant UED Team'
        />
      </List.Item>
    )}
  />
  );

  const onSubmitMessenger = (): void => {
    form.validateFields()
      .then(values => {
        form.resetFields();
        sendMessage(values.message);
        inputRef.current!.focus({ cursor: 'end' });
      })
      .catch(info => {
        console.error('Validate Failed:', info);
      });
  };

  const onPressEnter = async (e: React.KeyboardEvent<HTMLTextAreaElement>): Promise<void> => {
    if (e.nativeEvent.ctrlKey) {
      onSubmitMessenger();
    }
  };

  const addonAfter = (
    <div>
      <Modal title='Basic Modal'
        style={{ bottom: 0 }}
        visible={isModalVisible} mask={false} onOk={handleOk} onCancel={handleCancel}>
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </Modal>

      {false && (
        <Popover content={templateList} title={searchTemplate} trigger='click'>
          <Select defaultValue='Insertar' notFoundContent={null} bordered={false} style={{ width: 89, margin: 0, padding: 0 }}>
          </Select>
        </Popover>
      )}

      <Popover content={<IconsTable onIconClick={onIconClick} />} title={searchIcon} trigger='click'>
        <Button shape='circle' icon={<SmileOutlined />} style={{ border: 'none' }} />
      </Popover>
      <Upload {...props}>
        <Button shape='circle' icon={loading ? <LoadingOutlined /> : <PaperClipOutlined rotate={180} />} style={{ border: 'none' }} />
      </Upload>,

      <Button onClick={onSubmitMessenger} icon={<SendOutlined rotate={320} style={{ cursor: 'pointer' }} />} style={{ border: 'none', backgroundColor: 'green', color: 'white' }} />
    </div>
  );

  return <div id='ant' style={{ minWidth: 395, height: 90, paddingLeft: 5 }}>
    <Tabs size='small' defaultActiveKey='1'>
      <TabPane tab='Messenger' key='1' >
        <Form name='messenger' className='' form={form} >
          <Row justify='start'>
            <Col span={18}>
              <Form.Item name='message' rules={[{ required: true, message: t('insertMessage') }]}>
                <Input.TextArea
                  id='message'
                  ref={inputRef}
                  style={{ padding: 0, margin: 0 }}
                  required
                  maxLength={700}
                  onPressEnter={onPressEnter}
                  placeholder={t('writeMessage')} />
              </Form.Item>
            </Col>
            <Col span={6} style={{ textAlign: 'center' }} >
              {addonAfter}
            </Col>
          </Row>
        </Form>
      </TabPane>
    </Tabs>
  </div>;
};

export default WriteMessage;
