import { Col, Dropdown, Menu, Row, Space, Tabs, Typography } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import FilteredTable from '../Components/FilteredTable';
import DrawerContactForm from '../Components/DrawerContactForm';
import Banner from '../Components/Banner';
import NewView from '../Components/Contacts/NewView';
import { UserSolid } from '../Assets/Icons/IconsSolid';
import '../Assets/Styles/contacts.less';
import { DownOutlined, TeamOutlined } from '@ant-design/icons';
import Search from 'antd/lib/input/Search';
import APIClient from '../Services/APIClient';
import { ModuleId } from '../Constants/Enums';
import { User } from 'oidc-client';
import { IDataView, IViewBase } from '../common/types';
import { useTranslation } from 'react-i18next';
import { useModal } from '../Components/Contacts/UseModal';
import CreateView from '../Components/Contacts/CreateView';
import EditColumns from '../Components/Contacts/EditColumns';
import { newViewTab } from '../Constants/OptionsSelect';
import ButtonCreateContact from '../Components/Contacts/ButtonCreateContact';
import PinnedDataViewService from '../../src/Services/PinnedDataViewService';

const { Title } = Typography;

const Contacts: React.FC = () => {
  const { t } = useTranslation();
  const { isShown: isShownEditColumn, toggle: toggleEditColumn } = useModal();
  const [forceReload, setForceReload] = useState<boolean>(false);
  const { isShown, toggle } = useModal();
  const [editDataView, setEditDataView] = useState<IDataView>();
  const [dataViews, setDataViews] = useState<IDataView[]>([]);
  const [newViews, setNewViews] = useState<number[]>([]);
  const [user, setUser] = useState<User | null>();
  const [isContactFormOpen, setIsContactFormOpen] = useState<boolean>(false);
  // const [, setContactsLabel] = useState<string>();
  const [searchText, setSearchValue] = useState<string>('');
  const [modelBase] = useState<IViewBase>({ isDefault: 0 });
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    const apiClient = new APIClient();
    async function getDataViews (apiClient?: APIClient): Promise<void> {
      if (apiClient === undefined || apiClient === null) apiClient = new APIClient();
      const result = await apiClient.client.get(`/DataView/GetByShareType?moduleId=${ModuleId.Contacto}`);
      const tabs = result.data.concat(newViewTab());
      setDataViews(tabs);
    }
    getDataViews(apiClient);
    setUser(apiClient.getUser());
  }, []);

  const updateTotal = (totalRows: number): void => {
    setTotal(totalRows);
  };

  const selectView = (id?: number): void => {
    if (id) {
      newViews.push(id);
      setNewViews(newViews);
    }
  };

  async function getViews (id?: number): Promise<void> {
    selectView(id);
    const apiClient = new APIClient();
    const result = await apiClient.client.get(`/DataView/GetByShareType?moduleId=${ModuleId.Contacto}&newViews=${newViews}`);
    const tabs = result.data.concat(newViewTab());
    setDataViews(tabs);
  }

  const { TabPane } = Tabs;
  const openContactForm = (): void => setIsContactFormOpen(true);
  const closeContactForm = (reload: boolean): void => {
    setIsContactFormOpen(false);
    if (reload) setForceReload(!forceReload);
  };

  const OperationsSlot = {
    right: (
      <>
        <Search loading={false} placeholder={t('searchAccounts')} style={{ width: 300 }}
          onSearch={(value) => {
            setSearchValue(value);
          }}
        />
      </>
    )
  };

  const editView = (item: IDataView): void => {
    setEditDataView(item);
    toggle();
  };

  const editColumns = (item: IDataView): void => {
    setEditDataView(item);
    toggleEditColumn();
  };

  const pinnedView = (item: IDataView): void => {
    const pinnedDataViewService = new PinnedDataViewService();
    pinnedDataViewService.addPinnedView({ isActive: true, dataViewId: item.id });
    getViews();
  };

  const unpinView = (item: IDataView): void => {
    const pinnedDataViewService = new PinnedDataViewService();
    pinnedDataViewService.removePinnedView(item.id);
    getViews();
  };

  const menuTab = (item: IDataView): ReactElement => (
    <Menu mode='inline'>
      {item.isPinned
        ? <Menu.Item key="1" onClick={() => unpinView(item)} >
          {t('unpinView')}
        </Menu.Item>
        : <Menu.Item key="4" onClick={() => pinnedView(item)} >
          {t('anchorView')}
        </Menu.Item>
      }
      <Menu.Item key='2' hidden={item.owner !== user?.profile.sub} onClick={() => editView(item)} >
        {t('editView')}
      </Menu.Item>
      <Menu.Item key='3' hidden={item.owner !== user?.profile.sub} onClick={() => editColumns(item)} >
        {t('editColumns')}
      </Menu.Item>
    </Menu>
  );

  const tabPaneConditional = (item: IDataView): ReactElement => {
    if (item.id !== 0) {
      return <TabPane tabKey={'tab_' + item.id} key={item.id} tab={<>
        <Dropdown key={'dd_' + item.id} overlay={menuTab(item)} trigger={['click']}>
          <DownOutlined />
        </Dropdown>
        <span> <TeamOutlined key={'to_' + item.id} /> {item.name} </span></>
      }>
        <FilteredTable key={'ft_' + item.id} onClose={closeContactForm} forceReload={forceReload} reloadColumns={isShownEditColumn} search={searchText} dataViewId={item.id} companyAccountId={item.companyAccountId} updateTotal={updateTotal} />
      </TabPane>;
    } else {
      return <TabPane key='tp_1' disabled={true} tab={<NewView getViews={getViews} reloadViews={getViews} />}>
      </TabPane>;
    };
  };

  return (
    <>
      <EditColumns visible={isShownEditColumn} hide={toggleEditColumn} dataViewId={editDataView?.id} companyAccountId={editDataView?.companyAccountId.toString() || ''} />
      <CreateView visible={isShown} hide={toggle} reloadViews={getViews} dataView={editDataView} modelBase={modelBase} />
      <Banner>
        <div className="contacts-title">
          <Space>
            <Title style={{ marginBottom: 0 }} level={3}>{t('contacts')}</Title>
            <div className="contacts-counter">
              <UserSolid />
              <span>{total} {t('records')} </span>
            </div>
          </Space>
        </div>
        <ButtonCreateContact openContactForm={openContactForm} />
      </Banner>

      <Row className="generic-container crm-container">
        <Col span={24} className="tabs-container">
          <Tabs defaultActiveKey="0" tabBarExtraContent={OperationsSlot}>
            {dataViews.map(function (item: IDataView) {
              return tabPaneConditional(item);
            })}
          </Tabs>
        </Col>
      </Row>
      <DrawerContactForm
        isOpen={isContactFormOpen}
        onClose={closeContactForm}
      />
    </>);
};

export default Contacts;
