import { Modal, Form, Row, Col, message } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState, MouseEvent } from 'react';
import APIClient from '../../Services/APIClient';
import { useAuth } from 'oidc-react';
import { EditColumnsProps, DataType, ColumnType, VisibleFieldDataType } from '../../common/types';
import SortableTable from './SortableTable';
import VisibleFieldTable from './VisibleFieldTable';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { newDataType } from '../../Constants/OptionsSelect';
import arrayMove from 'array-move';
import { SortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

const EditColumns: React.FC<EditColumnsProps> = ({ hide, dataViewId, visible, companyAccountId }: EditColumnsProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Array<DataType>>([]);
  const auth = useAuth();

  const columns: Array<ColumnType> = [
    {
      title: t('sort'),
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: (text: string) => (
        <DragHandle />
      )
    },
    {
      title: t('field'),
      dataIndex: 'label',
      className: 'drag-visible'
    }
  ];

  useEffect(() => {
    async function loadData (): Promise<void> {
      setLoading(true);
      try {
        const apiClient = new APIClient();

        if (!!auth?.userData?.access_token && dataViewId !== undefined) {
          const result = await apiClient.client.get(`/VisibleField/GetByDataViewId?dataViewId=${dataViewId}`);
          setData(result.data);
        }
      } catch (err) { console.error(err); } finally { setLoading(false); }
    }
    loadData();
  }, [visible, dataViewId, auth?.userData?.access_token]);

  async function handleOk (values: Record<string, unknown>): Promise<void> {
    try {
      setLoading(true);
      if (!!auth?.userData?.access_token && dataViewId !== undefined) {
        const apiClient = new APIClient();

        for (let i = 0; i < data.length; i++) {
          data[i].position = i + 1;
        }
        const result = await apiClient.client.post('/VisibleField/SaveRange', { dataViewId, data: data });
        if (result) {
          message.success(t('visibleFieldSaveOk'), 5);
          hide();
        } else {
          message.error(t('visibleFieldSaveError'), 5);
        }
      }
    } catch (err) {
      message.error(t('visibleFieldSaveError'), 5);
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  const [form] = Form.useForm();

  const onChange = (e: CheckboxChangeEvent, record: VisibleFieldDataType, index: number): void => {
    const i = data.findIndex(p => p.fieldId === record.id);
    if (i >= 0) {
      const newList = data.filter((item) => item.fieldId !== record.id);
      for (let index = i; index < newList.length; index++) {
        if (newList[index] !== undefined) {
          newList[index].index = newList[index].index - 1;
        }
      }
      setData(newList);
    } else {
      const newType = newDataType(data.length + 1, record.label, 0, dataViewId, record.id, companyAccountId, data.length + 1);
      let oldData = data;

      oldData = oldData.concat(newType);
      setData(oldData);
    }
  };

  const onSortEnd = (obj: any, event: MouseEvent): void => {
    if (obj.oldIndex !== obj.newIndex) {
      const newData = arrayMove(data, obj.oldIndex, obj.newIndex).filter(el => !!el);
      let primerIndex = 0;
      for (let index = 0; index < newData.length; index++) {
        primerIndex = primerIndex + 1;
        newData[index].index = primerIndex;
        newData[index].position = primerIndex;
      }
      setData(newData);
    }
  };

  return (<>
    <Modal
      title={t('editColumns')}
      confirmLoading={loading}
      width={750}
      onCancel={hide}
      okText={t('save')}
      cancelText={t('cancel')}
      visible={visible}
      destroyOnClose={true}
      onOk={() => {
        form.validateFields()
          .then(values => {
            handleOk(values).then(() => form.resetFields());
          });
      }
      }
    >
      <Row>
        <Col span={10}>
          <VisibleFieldTable onChange={onChange} dataViewId={dataViewId} />
        </Col>
        <Col span={10} offset={3}>
          <SortableTable dataViewId={dataViewId} onSortEnd={onSortEnd} data={data} columns={columns} />
        </Col>
      </Row>
    </Modal>
  </>);
};

export default EditColumns;
