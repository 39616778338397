import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { User } from 'oidc-client';

export default class APIClient {
  client: AxiosInstance;

  constructor (url: string = process.env.REACT_APP_API_BASE_URL as string) {
    const user = this.getUser();
    const token = user?.access_token;

    this.client = axios.create({
      baseURL: url
    });

    console.log('USER', { user });

    this.client.defaults.headers.common.Authorization = `Bearer ${token}`;
    this.client.defaults.headers.common['Accept-Language'] = localStorage.getItem('i18nextLng');

    this.client.interceptors.response.use(
      (response) => response,
      (error) => {
        const { response } = error;
        return this.handleError(response);
      }
    );
  }

  getUser = () : User|null => {
    const oidcStorage = sessionStorage.getItem(`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`);
    if (!oidcStorage) {
      return null;
    }
    return User.fromStorageString(oidcStorage);
  }

  async options<T> (path: string): Promise<T> {
    const response = await this.client.options<T>(path);
    return response.data;
  }

  protected async get<T> (path: string): Promise<AxiosResponse<T>> {
    return this.client.get<T, AxiosResponse<T>>(path);
  }

  protected async post<T> (path: string, data?: unknown): Promise<AxiosResponse<T>> {
    return this.client.post<T, AxiosResponse<T>>(path, data);
  }

  protected async delete<T> (path: string): Promise<AxiosResponse<T>> {
    return this.client.delete<T, AxiosResponse<T>>(path);
  }

  protected async put<T> (path: string, data?: unknown): Promise<AxiosResponse<T>> {
    return this.client.put<T, AxiosResponse<T>>(path, data);
  }

  private handleError (error:any): Promise<never> {
    if (error === null || error === undefined) {
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
}
