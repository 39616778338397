import React, { FC as FunctionalComponent } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { IPipelineStage } from '../../models/Opportunities';
import BoardColumn from './BoardColumn';

type Props = {
    stages: IPipelineStage[];
    totalPrefix: string;
    OnDragEnd: (result: DropResult) => void;
    OnCardClick: () => void;
}

// Components
const BoardContainer = styled.div`
  overflow-x: auto;
  width: 100%;
  margin-top: 1rem;
  border: 1px solid #eaedea;
  border-radius: .5rem;
`;

const ColumnContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: nowrap;
`;

const Board: FunctionalComponent<Props> = ({ stages, totalPrefix, OnDragEnd, OnCardClick }) => {
  return (
      <BoardContainer>
        <ColumnContainer>
          <DragDropContext onDragEnd={OnDragEnd}>
            {stages && stages.map((column: IPipelineStage, index: number) => (
              <BoardColumn
                key={column.id}
                id={column.id}
                title={column.name}
                totalPrefix={totalPrefix}
                items={column.opportunities}
                index={index}
                OnCardClick={OnCardClick}
              />
            ))}
          </DragDropContext>
        </ColumnContainer>
      </BoardContainer>
  );
};

export default Board;
