import { FormSchema } from '../Components/DynamicForms/FormSchema';
import APIClient from './APIClient';
import { Result } from './Models/Result';
import { ModuleId } from '../Constants/Enums';
import { ContactResultType } from '../common/types';

export default class ContactsService extends APIClient {
  async getForm (): Promise<FormSchema> {
    const { data } = await this.get<FormSchema>('contacts/form');
    return data;
  }

  async save (contact: unknown): Promise<Result<number>> {
    const { data } = await this.post<Result<number>>('contacts', contact);
    return data;
  }

  async edit (contact: unknown): Promise<Result<number>> {
    const { data } = await this.put<Result<number>>('contacts', contact);
    return data;
  }

  async deactive (id: number): Promise<Result<number>> {
    const { data } = await this.put<Result<number>>(`contacts/Deactive?id=${id}`);
    return data;
  }

  async getContact (id:number) : Promise<ContactResultType> {
    const { data } = await this.get<Record<string, unknown | Record<string, unknown>>>(`contacts/${id}?moduleId=${ModuleId.Contacto}`);
    return data;
  }
}
