import React, { FC as FunctionalComponent, useEffect, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import styled, { css } from 'styled-components';
import { IOportunity } from '../../models/Opportunities';
import { Typography, List } from 'antd';
// import { Link } from 'react-router-dom';
import { Card as StyledCard } from '../../styled/Card';

type Props = {
  opportunity: IOportunity;
  index: number;
  OnClick: () => void;
}

interface IKeyValue {
  label: string;
  value: string;
}

const Card = styled(StyledCard)<{ isDragging: boolean }>`
  ${({ isDragging }) => isDragging && css`
    background-color: ${({ theme }) => theme.colorLight};
    border-color: ${({ theme }) => theme.colorGray};
    border-style: dashed;
    box-shadow: 0 0.75rem 1.5rem rgba(22, 28, 45, 3%);
  `}

  &:last-child {
    margin-bottom: 0;
  }
`;

const Title = styled.a`
  color: ${({ theme }) => theme.colorPrimary}
`;

const Accent = styled(Typography.Text)`
  color: ${({ theme }) => theme.colorAccent};

  &:after {
    content: ':';
  }
`;

const OpportunityCard: FunctionalComponent<Props> = ({ opportunity, index, OnClick }) => {
  const [properties, setProperties] = useState<IKeyValue[]>([]);

  useEffect(() => {
    const props: IKeyValue[] = [
      {
        label: 'Monto',
        value: opportunity.amount.toFixed(2)
      }
    ];

    setProperties(props);
  }, [opportunity]);

  return (
    <Draggable draggableId={`${opportunity.id}`} index={index}>
      {(provided, snapshot) => (
        <Card
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          isDragging={snapshot.isDragging}
        >
          <Title onClick={OnClick}>{opportunity.name}</Title>
          <List
            itemLayout="horizontal"
            dataSource={properties}
            renderItem={(item: IKeyValue) => (
              <List.Item style={{ padding: '0' }}>
                <Accent strong>{item.label}</Accent> {item.value}
              </List.Item>
            )}
          />
        </Card>
      )}
    </Draggable>
  );
};

export default OpportunityCard;
