import React from 'react';
import '../Assets/Styles/navMenu.less';
import { useTranslation } from 'react-i18next';
import UserNavMenu from './UserNavMenu';
import { Header } from 'antd/lib/layout/layout';
import { Menu } from 'antd';

import {
  Switch,
  Link
} from 'react-router-dom';

const NavMenu: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Header className='crm-navbar'>
      <div className='navbar-logo'>
        <h1>CRM</h1>
      </div>
      <div className='crm-navbar-content'>
        <Switch>
          <Menu theme='light' mode='horizontal' className='crm-navbar-menu'>
            <Menu.Item key='1'>
              <Link style={{ color: 'white' }} to='/'>{t('contacts')}</Link>
            </Menu.Item>
            <Menu.Item>
              <Link style={{ color: 'white' }} to='/chatroom'>Chatroom</Link>
            </Menu.Item>

            <Menu.Item>
              <Link style={{ color: 'white' }} to='/opportunities'>Oportunidades</Link>
            </Menu.Item>
          </Menu>

        </Switch>

        <UserNavMenu />
      </div>
    </Header>
  );
};

export default NavMenu;
