import React, { ReactNode } from 'react';
import AuditDetail from '../Audits/AuditDetail';
import { FieldProps } from './FieldProps';
import { ModuleId } from '../../Constants/Enums';
import { ValidationErrors } from '../../Services/Models/ValidationErrors';
import { ModalDynamicField } from './ModalDynamicField';

export const FormControlOptionsFactory = (id: number, companyAccountId: string, field: FieldProps, validationErrors?: ValidationErrors): Array<ReactNode> => {
  const getFormControlOptionsArray = (): Array<ReactNode> => {
    const optionsItemsArray: Array<ReactNode> = [];
    if (field.controlType === 'single-line-text' && field.internalName === 'email') {
      const item: ReactNode =
        <ModalDynamicField key={'modal_1'} entityId={id} companyAccountId={companyAccountId} field={field} validationErrors={validationErrors} />;
      optionsItemsArray.push(item);
    }
    optionsItemsArray.push(<AuditDetail key={'audit_1'} fieldId={field.fieldId} moduleId={ModuleId.Contacto} itemId={id} />);
    return optionsItemsArray;
  };

  return getFormControlOptionsArray();
};
