import React, { ReactElement, useState } from 'react';
import '../Assets/Styles/drawerContactForm.less';
import ResizableDrawer from './ResizableDrawer';
import debounce from 'lodash.debounce';
import ContactFormAssembly from './ContactFormAssembly';
import { useTranslation } from 'react-i18next';
import { ModeForm } from '../Constants/Enums';

interface DrawerContactFormProps {
  isOpen: boolean;
  onClose: (forceReload: boolean) => void;
  id?: number;
  modeFormId?: ModeForm;
}

const DrawerContactForm: React.FC<DrawerContactFormProps> =
  ({ isOpen, onClose, id, modeFormId = ModeForm.Create }
    : DrawerContactFormProps): ReactElement => {
    const contactFormWidthKey = 'contactFormWidth';
    const [title, setTitle] = useState('');
    const [drawerWidth, setDrawerWidth] = useState(parseInt(localStorage.getItem(contactFormWidthKey) || '450'));
    const { t } = useTranslation();

    const updateTitle = (title: string): void => {
      setTitle(title);
    };

    const debouncedSaveWidth =
      debounce(nextValue => {
        localStorage.setItem(contactFormWidthKey, nextValue);
      }, 1000);

    const onDrawerResize = (sizePx: number): void => {
      setDrawerWidth(sizePx);
      debouncedSaveWidth(sizePx);
    };

    return <ResizableDrawer
      title={title || t('createNewContact')}
      width={drawerWidth}
      minWidth={450}
      maxWidth={1200}
      onClose={() => onClose(false)}
      visible={isOpen}
      onResize={onDrawerResize}
    >
      <ContactFormAssembly onClose={onClose} visible={true} modeFormId={modeFormId} title={title} id={id} updateTitle={updateTitle} />
    </ResizableDrawer >;
  };

export default DrawerContactForm;
