import React, { MouseEvent } from 'react';
import { Drawer, DrawerProps } from 'antd';

let isResizing: boolean | null = null;

interface ResizebleDrawerProps extends DrawerProps {
  children: React.ReactNode;
  width: number;
  minWidth: number;
  maxWidth: number;
  onResize: (sizePx: number) => void;
}

const ResizableDrawer: React.FC<ResizebleDrawerProps> = ({ children, width, onResize, maxWidth, minWidth, ...props }: ResizebleDrawerProps) => {
  const handleMousemove = (e: globalThis.MouseEvent): void => {
    const offsetRight = document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
    if (offsetRight > minWidth && offsetRight < maxWidth) {
      onResize(offsetRight);
    }
  };

  const cbHandleMouseMove = React.useCallback(handleMousemove, [minWidth, maxWidth, onResize]);

  const cbHandleMouseUp = React.useCallback(() => {
    const handleMouseup = (): void => {
      if (!isResizing) {
        return;
      }
      isResizing = false;
      document.removeEventListener('mousemove', cbHandleMouseMove);
      document.removeEventListener('mouseup', cbHandleMouseUp);
    };
    handleMouseup();
  }, [cbHandleMouseMove]);

  function handleMousedown (e: MouseEvent<HTMLDivElement>): void {
    e.stopPropagation();
    e.preventDefault();

    document.addEventListener('mousemove', cbHandleMouseMove);
    document.addEventListener('mouseup', cbHandleMouseUp);
    isResizing = true;
  }

  return (
    <Drawer {...props} closable width={width}>
      <div className='sidebar-dragger' onMouseDown={handleMousedown} />
      {children}
    </Drawer>
  );
};

export default ResizableDrawer;
