import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.less';
import Layout from './Components/Layout';
import { AuthProvider, AuthProviderProps } from 'oidc-react';
import Callback from './Pages/Callback';
import Contacts from './Pages/Contacts';
import Accounts from './Pages/Accounts';
import ChatRoom from './Pages/ChatRoom';
import { ConfigProvider } from 'antd';
import esES from 'antd/lib/locale/es_ES';
import enUS from 'antd/lib/locale/en_US';
import { useTranslation } from 'react-i18next';
import React, { ReactElement, useEffect } from 'react';
import ChatTest from './Pages/ChatTest';
import Opportunities from './Pages/Opportunities';
import { ThemeProvider } from 'styled-components';

const oidcConfig: AuthProviderProps = {
  authority: process.env.REACT_APP_AUTH_URL,
  clientId: process.env.REACT_APP_IDENTITY_CLIENT_ID,
  redirectUri: process.env.REACT_APP_REDIRECT_URL,
  scope: process.env.REACT_APP_SCOPE,
  loadUserInfo: true,
  postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI
};

// Define what props.theme will look like
const theme = {
  colorContrast: '#ffffff',
  colorLight: '#f9fbfd',
  colorGray: '#caddda',
  colorPrimary: '#e61b26',
  colorAccent: '#707070',
  borderColor: '#eaedea',
  borderRadius: '.5rem'
};

function App (): ReactElement {
  const { i18n: { language } } = useTranslation();
  const locale = language === 'es' ? esES : enUS;

  useEffect(() => {
    console.log('App.tsx');
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <ConfigProvider locale={locale}>
          <AuthProvider {...oidcConfig}>
            <Layout>
                <Switch>
                  <Route exact path='/callback' component={Callback} />
                  <Route exact path='/accounts' component={Accounts} />
                  <Route exact path='/chatroom' component={ChatRoom} />
                  <Route exact path='/opportunities' component={Opportunities} />
                  <Route exact path='/chat-test' component={ChatTest} />
                  <Route exact path='/' component={Contacts} />
                </Switch>
            </Layout>
          </AuthProvider>
        </ConfigProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
