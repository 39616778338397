import { Col, message, Row, Table, TablePaginationConfig } from 'antd';
import React, { useState, useEffect, ReactElement } from 'react';
import { TableCurrentDataSource, SorterResult, FilterValue } from 'antd/lib/table/interface';
import APIClient from '../Services/APIClient';
import { useAuth } from 'oidc-react';
import { useTranslation } from 'react-i18next';
import { EditContactButton, DeleteContactButton } from './Contacts/EditContactButton';
import { RealTimeData } from './RealTimeData';
import { DataType, DynamicData } from '../common/types';
import moment from 'moment';
import '../Assets/Styles/dragColumn.less';

const ReactDragListView = require('react-drag-listview').default;

interface FormProps {
  search: string;
  dataViewId: number;
  companyAccountId: string;
  reloadColumns: boolean;
  forceReload: boolean;
  onClose: (forceReload: boolean) => void;
  updateTotal: (totalRows: number) => void;
}

const FilteredTable: React.FC<FormProps> = (props: FormProps) => {
  interface IColumn {
    key: number,
    fieldId: number,
    name: string,
    dataIndex: string,
    dataType: string,
    sorter: () => number,
    className?: string
  }
  interface IRecord {
    itemid: number;
  }
  const auth = useAuth();
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState(Array<IColumn>());
  const [total, setTotal] = useState<number | undefined>();
  const [pageSize, setPageSize] = useState<number | undefined>(10);
  const [currentPage, setCurrentPage] = useState<number | undefined>(1);
  const [sorterPage, setSorter] = useState<SorterResult<IColumn> | SorterResult<IColumn>[]>();
  const [newItemId, setNewItemId] = useState(Array<number>());
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const addAditionalPropertiesToColumns = (cols: any[]): void => {
    cols.forEach(c => { c.className = 'drag-visible'; });
  };

  useEffect(() => {
    async function loadData (): Promise<any> {
      try {
        const EditButton = (itemid: number): ReactElement => <EditContactButton onClose={props.onClose} itemid={itemid} />;
        const DeleteButton = (itemid: number): ReactElement => <DeleteContactButton onClose={props.onClose} itemid={itemid} />;
        const ActionButtons = (itemid: number): ReactElement => {
          return <>
            {EditButton(itemid)}
            {DeleteButton(itemid)}
          </>;
        };

        if (auth?.userData?.access_token) {
          setLoading(true);
          const apiClient = new APIClient();
          const result = await apiClient.client.post(`/DataView/GetData?id=${props.dataViewId}&pageNumber=${currentPage}&pageSize=${pageSize}`, { search: props.search, ...sorterPage });
          let columns = result.data.columns;
          addAditionalPropertiesToColumns(columns);
          const actionColumn = {
            title: t('action'),
            key: 'action',
            render: (record: IRecord) => ActionButtons(record.itemid)
          };
          // TODO: Revisar esta linea, para saber cual es la que vamos a dejar.
          columns.map((col: any) => { col.sorter = () => 0; col.render = (text: string) => (col.dataType.toLowerCase().includes('datetime')) ? moment(text).format('DD-MM-YYYY h:mm:ss a') : text; return col; });
          columns = [actionColumn, ...columns];
          // TODO: Revisar esta linea, para saber cual es la que vamos a dejar.
          columns.map((col: any) => { col.sorter = () => 0; col.sortDirections = ['descend', 'ascend', 'descend']; return col; });
          setColumns(columns);
          setData(result.data.data);
          setTotal(result.data.data[0].overall_count);
          props.updateTotal(total || 0);
          // setNewItemId([]);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    }
    loadData();
  }, [props.onClose, t, props.search, props.forceReload, props.reloadColumns, props.dataViewId, sorterPage, currentPage, pageSize, auth?.userData?.access_token, props, total]);

  function onchange (pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<IColumn> | SorterResult<IColumn>[],
    extra: TableCurrentDataSource<IColumn>): void {
    setPageSize(pagination?.pageSize);
    setCurrentPage(pagination.current);
    setSorter(sorter);
  }

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: IColumn[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    }
  };

  const rowClassBold = (record: DynamicData, index: number): string => {
    return newItemId.includes(record.itemid as never) ? 'bold' : '';
  };

  const handleNewData = async (newData: any): Promise<void> => {
    setLoading(true);
    const newIds = newData.map((i: any) => i.itemid);
    setNewItemId(prevNewIds => [...newIds as never, ...prevNewIds]);
    setData(prevData => [...newData as never, ...prevData]);
    setTotal(prevTotal => prevTotal ? prevTotal + 1 : 1);
    setLoading(false);
  };

  const saveColumnsOrder = async (draggableCols: IColumn[]): Promise<void> => {
    if (!!auth?.userData?.access_token && props.dataViewId !== undefined) {
      const apiClient = new APIClient();
      const cols: Array<DataType> = [];

      for (let i = 0; i < draggableCols.length; i++) {
        const col: DataType = {
          index: i,
          label: draggableCols[i].dataIndex,
          key: i,
          fieldId: draggableCols[i].fieldId,
          companyAccountId: props.companyAccountId,
          dataViewId: props.dataViewId,
          position: i + 1
        };
        cols.push(col);
      }
      const result = await apiClient.client.post('/VisibleField/SaveRange', { dataViewId: props.dataViewId, data: cols });
      if (result) {
        message.success(t('visibleFieldSaveOk'), 5);
      } else {
        message.error(t('visibleFieldSaveError'), 5);
      }
    }
  };

  const dragProps = {
    onDragEnd (fromIndex: number, toIndex: number) {
      const fixedCols = columns.filter(col => !col.className?.includes('drag-visible'));
      const draggableCols = columns.filter(col => col.className?.includes('drag-visible'));
      fromIndex -= fixedCols.length + 1;
      toIndex -= fixedCols.length + 1;
      const item = draggableCols.splice(fromIndex, 1)[0];
      draggableCols.splice(toIndex, 0, item as never);
      setColumns([...fixedCols, ...draggableCols]);
      saveColumnsOrder(draggableCols);
    },
    nodeSelector: 'th.drag-visible'
  };

  return (
    <Row>
      <Col span={24}>
        <ReactDragListView.DragColumn {...dragProps}>
          <Table id='filtered-table'
            columns={columns}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection
            }}
            rowClassName={rowClassBold}
            loading={loading}
            dataSource={data}
            rowKey='itemid'
            size='large'
            onChange={onchange}
            scroll={{ x: 1500 }}
            pagination={{ showSizeChanger: true, current: currentPage, pageSize: pageSize, total: total }}
          />
        </ReactDragListView.DragColumn>
        <RealTimeData onAddNewData={handleNewData} />
      </Col>
    </Row>
  );
};

export default FilteredTable;
