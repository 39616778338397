
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { User } from 'oidc-react';
import React, { useEffect, useState } from 'react';
import APIClient from '../Services/APIClient';

const ChatTest: React.FC = () => {
  const [user, setUser] = useState<User | null>();
  const [, setConnection] = useState<HubConnection | null>(null);

  useEffect(() => {
    const apiClient = new APIClient();
    setUser(apiClient.getUser());
  }, []);

  useEffect(() => {
    if (typeof user?.profile?.sub !== 'undefined') {
      const newConnection = new HubConnectionBuilder()
        .withUrl(`${process.env.REACT_APP_API_BASE_HUB}/hubs/chat`)
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect()
        .build();

      newConnection.start()
        .then(result => {
          const apiClient = new APIClient();
          newConnection.invoke('Join', { UserId: user?.profile?.sub });

          newConnection.on('ReceiveMessage', message => {
          });

          newConnection.on('UpdateConversationList', message => {
            apiClient.client.get('conversation').then((result) => {
            });
          });
        })
        .catch(e => console.log('Connection failed: ', e));

      setConnection(newConnection);
    }
  }, [user?.profile?.sub]);

  return <></>;
};

export default ChatTest;
