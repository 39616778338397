import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import React, { useEffect, useState } from 'react';
import dotenv from 'dotenv';
import APIClient from '../Services/APIClient';
import { User } from 'oidc-react';

dotenv.config();

interface RealTimeDataProps {
  onAddNewData: (data: any) => Promise<void>;
}

export const RealTimeData: React.FC<RealTimeDataProps> = (
  {
    onAddNewData
  }: RealTimeDataProps) => {
  const [connection, setConnection] = useState<null | HubConnection>(null);

  useEffect(() => {
    const connect = new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API_BASE_HUB}/hubs/data`)
      .withAutomaticReconnect()
      .build();

    setConnection(connect);
  }, []);

  useEffect(() => {
    if (connection?.state === HubConnectionState.Disconnected) {
      connection
        .start()
        .then(() => {
          connection.on('ReceiveData', (message: any) => {
            if (onAddNewData) {
              onAddNewData(message.data);
            }
          });
        })
        .catch((error) => console.error(error));
    }
  }, [connection, onAddNewData]);

  return (
    <></>
  );
};

interface ConversationRealTimeProps {
  reload: () => Promise<void>;
}

export const ConversationRealTime: React.FC<ConversationRealTimeProps> = ({ reload }: ConversationRealTimeProps) => {
  const [connection, setConnection] = useState<null | HubConnection>(null);
  const [user, setUser] = useState<User | null>();

  useEffect(() => {
    const apiClient = new APIClient();
    setUser(apiClient.getUser());

    const connect = new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API_BASE_HUB}/hubs/conversation`)
      .withAutomaticReconnect()
      .build();

    setConnection(connect);
  }, []);

  useEffect(() => {
    if (connection?.state === HubConnectionState.Disconnected) {
      connection
        .start()
        .then(() => {
          console.log(user);
          connection.invoke('Join', { UserId: user?.profile?.sub });
          connection.on('UpdateConversationList', (message: any) => {
            console.log('UpdateConversationList', message);
            reload();
          });
        })
        .catch((error) => console.error(error));
    }
  }, [connection, user, reload]);

  return (
    <></>
  );
};
