import APIClient from './APIClient';
import { Result } from './Models/Result';

export default class PinnedDataViewService extends APIClient {
  async addPinnedView (model: pinnedDataView): Promise<Result<number>> {
    const { data } = await this.post<Result<number>>('pinnedDataView', model);
    return data;
  }

  async removePinnedView (id: number): Promise<Result<number>> {
    const { data } = await this.delete<Result<number>>(`pinnedDataView/${id}`);
    return data;
  }
}

export interface pinnedDataView {
  dataViewId: number;
  isActive: boolean;
}
