import { Modal, Button, Table, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table/Table';
import Search from 'antd/lib/input/Search';
import { AuditOutlined } from '@ant-design/icons';
import APIClient from '../../Services/APIClient';
import { AudtiDetailVM, IAuditDetailProps } from '../../common/types';
import { useAuth } from 'oidc-react';

const ActivityDetail: React.FC<IAuditDetailProps> = ({ itemId, fieldId, moduleId }: IAuditDetailProps) => {
  const { t } = useTranslation();
  const auth = useAuth();

  const [visible, setVisible] = useState(Boolean);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Array<AudtiDetailVM>>([]);
  const [dataFiltered, setFiltered] = useState<Array<AudtiDetailVM>>([]);

  const loadData = async (): Promise<any> => {
    try {
      if (auth?.userData?.access_token) {
        const apiClient = new APIClient();

        if (itemId) {
          setLoading(true);
          const result = await apiClient.client.get(`/AuditDetail?itemId=${itemId}&fieldId=${fieldId}&moduleId=${moduleId}`);
          setData(result.data.results);
          setFiltered(result.data.results);
          setLoading(false);
        }
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const showModal = (): void => {
    setVisible(true);
    loadData();
  };

  const handleOk = (): void => {
    setVisible(false);
  };

  const handleCancel = (): void => {
    setVisible(false);
  };

  const filterTable = (filterText: string): void => {
    if (filterText.length > 0) {
      const filtered = data.filter((item) =>
        item.afterValue.toLowerCase().indexOf(filterText.toLowerCase()) > -1 ||
        item.createdIn.toLowerCase().indexOf(filterText.toLowerCase()) > -1
      );
      setFiltered(filtered);
    } else {
      setFiltered(data);
    }
  };

  const columns: ColumnsType<AudtiDetailVM> = [
    {
      key: 'afterValue',
      title: t('afterValue'),
      dataIndex: 'afterValue',
      render: (value: string, record: AudtiDetailVM) => record.catalogLabel && record.catalogLabel.length > 0 ? record.catalogLabel : value
    },
    {
      key: 'createdByUserName',
      title: t('createdBy'),
      dataIndex: 'createdByUserName'
    },
    {
      key: 'createdIn',
      title: t('createdIn'),
      dataIndex: 'createdIn'
    },
    {
      key: 'createdOn',
      title: t('createdEn'),
      dataIndex: 'createdOn',
      render: (value: string) => new Date(value).toLocaleDateString()
    }

  ];

  return (
    <>
      <Tooltip placement="topRight" title={t('history')}>
        <Button onClick={showModal} icon={<AuditOutlined />}></Button>
      </Tooltip>
      <Modal closable={false} visible={visible} width={800} zIndex={1040}
        title={
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between' }} >
            <div style={{ width: 200 }}>
              {data?.length > 0 ? data[0].label : ''}
              <br />
              <div style={{ fontWeight: 'normal' }}>
                {data?.length > 0 ? data[0].description : ''}
              </div>
            </div>
            <div style={{}}>
              <Search allowClear={true} placeholder={t('searchHistory')} onSearch={(value) => filterTable(value)} style={{ width: 300 }} />
            </div>
          </div>}
        onCancel={handleCancel}
        footer={[
          <Button key='newView' type='primary' onClick={handleOk}>{t('close')}</Button>
        ]}>
        <div style={{ overflowY: 'scroll' }}>
          <Table<AudtiDetailVM> loading={loading} rowKey={row => row.id} columns={columns} dataSource={dataFiltered} pagination={false} size='small' showHeader={true} />
        </div>
      </Modal>
    </>
  );
};

export default ActivityDetail;
