import React, { ReactElement } from 'react';
import { Image } from 'antd';
import { ConversationDetailVm } from '../../common/types';
import { FilePdfOutlined, PictureOutlined, PlaySquareOutlined, SoundOutlined } from '@ant-design/icons';
import '../../Assets/Styles/attachment.less';

interface props {
  item: ConversationDetailVm;
}

const AttachmentMessage: React.FC<props> = ({ item }: props) => {
  const getIcon = (): ReactElement => {
    if (item.mediaContentType?.includes('pdf')) return <FilePdfOutlined className='icon-attachment' />;
    else if (item.mediaContentType?.includes('video')) return <PlaySquareOutlined className='icon-attachment' />;
    else if (item.mediaContentType?.includes('audio')) return <SoundOutlined className='icon-attachment' />;
    return <></>;
  };

  const renderAttachment = (): ReactElement => {
    if (item.mediaContentType?.includes('image')) {
      return (
        <Image src={item.mediaUrl} height={150} alt={item.body} placeholder={<PictureOutlined />} />
      );
    } else {
      return (
        <a href={item.mediaUrl} target='_blank' rel='noreferrer' style={{ width: 'max-content' }}>
          {getIcon()}
          {item.body === '' ? item.mediaUrl : item.body}
        </a>
      );
    }
  };

  return (
    renderAttachment()
  );
};

export default AttachmentMessage;
