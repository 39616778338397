import { CaretDownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Popover } from 'antd';
import MenuItem from 'antd/lib/menu/MenuItem';
import { MenuInfo } from 'rc-menu/lib/interface';
import React, { ReactNode, useState } from 'react';

interface IFormControlOptionsProps {
  menuItems: Array<ReactNode>
}

const FormControlOptions: React.FC<IFormControlOptionsProps> = ({ menuItems }: IFormControlOptionsProps) => {
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (flag: boolean): void => {
    setVisible(flag);
  };

  const handleMenuClick = (e: MenuInfo): void => {
    const item: MenuItem = e.item as MenuItem;
    const itemChildren = item.props.children as Array<ReactNode>;
    const childrenPopover = itemChildren.filter((el: ReactNode): any => (el as React.ReactElement)?.type === Popover);
    setVisible(childrenPopover.length > 0);
  };

  const overlayFormControlOptionsMenu = (
    <Menu onClick={handleMenuClick}>
      {menuItems.map((item, index) => (
        <Menu.Item key={index}>
          {item}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <Dropdown
        overlay={overlayFormControlOptionsMenu}
        placement='bottomRight'
        onVisibleChange={handleVisibleChange}
        visible={visible}
      >
        <Button onClick={e => e.preventDefault} icon={<CaretDownOutlined />} />
      </Dropdown>
    </>
  );
};

export default FormControlOptions;
