import React from 'react';
import NavMenu from './NavMenu';
import { Layout as AntdLayout } from 'antd';

interface props {
  children: React.ReactNode
}
const Layout: React.FC<props> = ({ children }: props) => {
  return (
    <AntdLayout>
      <NavMenu />
      {children}
    </AntdLayout>
  );
};

export default Layout;
