import React, { FC as FunctionalComponent } from 'react';
import { Tabs } from 'antd';

// import { useTranslation } from 'react-i18next';
import { ModeForm } from '../../Constants/Enums';
import ResizableDrawer from '../ResizableDrawer';

interface Props {
    isOpen: boolean;
    onClose: (forceReload: boolean) => void;
    id?: number;
    modeFormId?: ModeForm;
  }

const OpportunityDrawer: FunctionalComponent<Props> = ({ isOpen, onClose, id, modeFormId = ModeForm.Create }) => {
  // const { t } = useTranslation();
  return (
    <ResizableDrawer
    title='Oportunidad'
    width={350}
    minWidth={450}
    maxWidth={1200}
    onClose={() => onClose(false)}
    visible={isOpen}
    onResize={() => { }}
    >
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Formulario" key="1">
          Formulario
        </Tabs.TabPane>

        <Tabs.TabPane tab="Auditoría de Cambios" key="2">
          Auditoria de Cambios
        </Tabs.TabPane>
      </Tabs>
    </ResizableDrawer >
  );
};

export default OpportunityDrawer;
