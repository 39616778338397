import React, { useEffect, useState } from 'react';
import { Col, Layout, Row, Tabs, Divider, Space, Typography, Select } from 'antd';
import ContactHeader from './ContactHeader';
import DynamicContactForm from './Forms/DynamicContactForm';
import { useTranslation } from 'react-i18next';
import { ModeForm } from '../Constants/Enums';
import InfiniteScrollReverse from './Scrollers/InfiniteScrollReverse';
import ConversationService from '../Services/ConversationService';
import { useAuth } from 'oidc-react';
import { ChannelVm, ConversationContactInfoVm } from '../common/types';
import { SearchMessage } from './ChatRooms/HeaderButtonSearchMessage';
import ChannelService from '../Services/ChannelService';

interface ContactFormProps {
  title: string;
  id: number | undefined;
  updateTitle: (title: string) => void;
  modeFormId: ModeForm;
  visible: boolean;
  onClose?: (forceReload: boolean) => void;
}

const { Text } = Typography;

const ContactFormAssembly: React.FC<ContactFormProps> = ({ title, onClose, updateTitle, id, modeFormId = ModeForm.Create, visible }: ContactFormProps) => {
  const { TabPane } = Tabs;
  const auth = useAuth();

  const { t } = useTranslation();
  const [conversationContactInfo, setConversationContactInfoVm] = useState<ConversationContactInfoVm>({ id: 0, serviceName: '' });
  const [searchText, setSearchText] = useState('');
  const [pageSize] = useState<number>(0);
  const [currentPage] = useState<number>(1);
  const [channelData, setChannelData] = useState<Array<ChannelVm>>([]);
  const [firsItem, setFirstItem] = useState<ChannelVm>();

  useEffect(() => {
    const service = new ConversationService();
    const channelService = new ChannelService();
    const init = async (): Promise<void> => {
      if (id) {
        const selectedKeys = await service.getConversationIdByEntityId(id);
        setConversationContactInfoVm(selectedKeys);
      }
      const channels = await channelService.getChannels(currentPage, pageSize);
      setChannelData(channels.results);
      setFirstItem(channels.results[0]);
    };
    init();
  }, [visible, id, currentPage, pageSize]);

  const onNameUpdate = (title: string): void => {
    updateTitle(title);
  };

  const onSearch = (searchText: string): void => {
    setSearchText(searchText);
  };

  return <>{visible && <Row wrap={true} align='top'>
    <Col span={24} className='tabs-container' >
      <Tabs defaultActiveKey='0'>
        <TabPane tab={t('properties')} key='1'>
          <ContactHeader name={title} />
          <br />
          <DynamicContactForm onClose={onClose} id={id} onNameUpdate={onNameUpdate} modeFormId={modeFormId} />
        </TabPane>
        <TabPane tab={t('oportunities')} key='3'>
        </TabPane>
        <TabPane tab={t('chat')} key='4'>
          <Select style={{ width: 200 }} defaultValue={firsItem?.id} >
            {channelData.map((item, index) => {
              return <Select.Option key={index} value={item.id}>{item.name}</Select.Option>;
            })}
          </Select>
          <br></br>
          <br></br>
          <div style={{ marginBottom: 30, border: '2px solid' }}>
            <Space direction='vertical'>
              <Text type='secondary'>{conversationContactInfo.serviceName}</Text>
              <Text strong>{auth?.userData?.profile.name}</Text>
            </Space>
            <Layout.Header className='ant-layout-header-chatroom'>
              <SearchMessage onSearch={onSearch} />
            </Layout.Header>
            <Divider style={{ marginBottom: 0, paddingBottom: 0, marginTop: 0, paddingTop: 0 }} />
            <InfiniteScrollReverse searchText={searchText} selectedKey={conversationContactInfo.id} />
          </div>
        </TabPane>
      </Tabs>
    </Col>
  </Row>}</>;
};

export default ContactFormAssembly;
