import { Input, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface HeaderChatProps {
  onSearch: (text: string) => void,
}

export const SearchMessage: React.FC<HeaderChatProps> = ({ onSearch }: HeaderChatProps) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = React.useState('');
  const onSearchChange = (value: string): void => {
    setSearchValue(value);
  };

  const onSearchIconClick = (): void => {
    onSearch(searchValue);
  };

  return <>
    <Tooltip placement='top' title={t('searchMessage')}>
      <Input bordered={false} inputMode="search"
        placeholder={t('searchMessage')} allowClear
        onChange={(e) => onSearchChange(e.currentTarget.defaultValue)}
        onPressEnter={(e) => onSearch(e.currentTarget.defaultValue)} style={{ paddingRight: 0, width: 200 }} />
      <SearchOutlined onClick={onSearchIconClick} style={{ cursor: 'pointer', fontSize: 22, color: 'red', marginRight: 0, marginLeft: 0 }} />
    </Tooltip>
  </>;
};
