import React, { FC as FunctionalComponent, useEffect, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { IOportunity } from '../../models/Opportunities';
import OpportunityCard from '../Opportunity/OpportunityCard';

type Props = {
  id: number;
  title: string;
  totalPrefix: string;
  items: IOportunity[] | undefined;
  index: number;
  OnCardClick: () => void;
}

// Components
const Container = styled.div`
  border-right: 1px solid #eaedea;
  width: 260px;
  display: flex;
  flex-direction: column;

  &:last-child {
    border-right: none;
    flex: 1;
    width: auto;
  }
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  background-color: #fafafa;
  transition: background-color 0.2s ease;
  border-bottom: 1px solid #eaedea;
`;

const ColumnTotal = styled.div`
  padding: .5rem;
  border-bottom: 1px solid #eaedea;
`;

const ColumnTitle = styled.div`
  padding: .5rem;
  font-weight: bold;
`;

const ColumnList = styled.div<{ isDraggingOver: boolean }>`
  padding: .5rem;
  background-color: ${props => props.isDraggingOver ? props.theme.colorLight : '#fff'};
  flex-grow: 1;
  transition: background-color .3s ease;
`;

const BoardColumn: FunctionalComponent<Props> = ({ id, title, totalPrefix, items, OnCardClick }) => {
  const [total, setTotal] = useState<number>();

  useEffect(() => {
    const total = items?.length ? items?.map(item => item.amount).reduce((prev, curr) => prev + curr) : 0;

    setTotal(total);
  }, [items]);

  return (
      <Container>
        <Header>
          <ColumnTitle>{title}</ColumnTitle>
        </Header>

        <ColumnTotal>
          {totalPrefix} {total}
        </ColumnTotal>

        <Droppable droppableId={`${id}`}>
          {(provided, snapshot) => (
            <ColumnList
              {...provided.droppableProps}
              ref={provided.innerRef}
              isDraggingOver={snapshot.isDraggingOver}
            >
              {items?.map((op, index) => <OpportunityCard key={op.id} opportunity={op} index={index} OnClick={OnCardClick} />)}
              {provided.placeholder}
            </ColumnList>
          )}
        </Droppable>
      </Container>
  );
};

export default BoardColumn;
