import { Button, Tooltip, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import DrawerContactForm from '../../Components/DrawerContactForm';
import React, { useState } from 'react';
import { ModeForm } from '../../Constants/Enums';
import ContactsService from '../../Services/ContactsService';

interface EditContactProps {
  itemid: number;
  onClose: (forceReload: boolean) => void;
}

interface DeleteContactProps {
  itemid: number;
  onClose: (forceReload: boolean) => void;
}

export const EditContactButton: React.FC<EditContactProps> = ({ itemid, onClose }: EditContactProps) => {
  const [isContactFormOpen, setIsContactFormOpen] = useState<boolean>(false);
  const closeContactForm = (forceReload: boolean): void => {
    setIsContactFormOpen(false);
    onClose(forceReload);
  };
  const openContactForm = (): void => setIsContactFormOpen(true);
  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={t('editContact')}>
        <Button style={{ border: 'none' }} type='default' onClick={openContactForm} icon={<EditOutlined />} />
      </Tooltip>
      <DrawerContactForm
        id={itemid}
        isOpen={isContactFormOpen}
        onClose={closeContactForm}
        modeFormId={ModeForm.Edit}
      />
    </>
  );
};

export const DeleteContactButton: React.FC<DeleteContactProps> = ({ itemid, onClose }: DeleteContactProps) => {
  const { t } = useTranslation();
  const service = new ContactsService();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleOk = async (): Promise<void> => {
    setConfirmLoading(true);
    await service.deactive(itemid).finally(() => {
      setVisible(false);
      setConfirmLoading(false);
      onClose(true);
    });
  };

  return (
    <Tooltip title={t('deleteContact')}>
      <Popconfirm
        okButtonProps={{ loading: confirmLoading }}
        onCancel={() => setVisible(false)}
        visible={visible}
        title={t('confirmDeleteContact')}
        onConfirm={handleOk}>
        <Button style={{ border: 'none' }}
          onClick={() => setVisible(true)}
          type='default' icon={<DeleteOutlined />} />
      </Popconfirm>
    </Tooltip>
  );
};
