import APIClient from './APIClient';
import { ConversationContactInfoVm, ConversationVm, ConversationCounterVm } from '../common/types';
import { Result } from './Models/Result';

export default class ConversationService extends APIClient {
  async getByUser (
    serviceFilterKeys: Array<number>,
    order: boolean,
    statusId: number|undefined
  ): Promise<Array<ConversationVm>> {
    const { data } = await this.get<Array<ConversationVm>>(
      `Conversation?serviceFilterKeys=${serviceFilterKeys}&order=${order}&statusId=${statusId}`
    );
    return data;
  }

  async getConversationIdByEntityId (
    entityId: number
  ): Promise<ConversationContactInfoVm> {
    const { data } = await this.get<ConversationContactInfoVm>(
      `Conversation/GetConversationIdByEntityId?entityId=${entityId}`
    );
    return data;
  }

  async getCounterStatus (): Promise<Array<ConversationCounterVm>> {
    try {
      const { data } = await this.get<Array<ConversationCounterVm>>('/Conversation/GetCounter');
      return data;
    } catch (e: any) {
      return e.data;
    }
  }

  async updateStatus (statusId: number, id: number): Promise<Result<number>> {
    try {
      const { data } = await this.put<Result<number>>('Conversation', { statusId, id });
      return data;
    } catch (e: any) {
      return e.data;
    }
  }

  async updateClasification (conversationClasificationId: number, id: number): Promise<Result<number>> {
    try {
      const { data } = await this.put<Result<number>>('Conversation', { conversationClasificationId, id });
      return data;
    } catch (e: any) {
      return e.data;
    }
  }
}
