import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ConversationType, StatusChat } from '../Constants/Enums';
import moment from 'moment';
import React from 'react';
import { JSONSchema7 } from 'json-schema';

export interface FilterOrderProps {
  textSearch: string;
  groupProperty: string;
  order: boolean;
  serviceFilterKeys: Array<number>;
  statusId: number;
}

export interface ConversationCounterVm {
  statusId:number,
  count:number,
}

export interface ConversationContactInfoVm {
  id: number;
  serviceName: string;
}

export interface IDataView {
  companyAccountId: string;
  id: number;
  moduleId: number;
  name: string;
  owner: string;
  shareTypeId: number;
  sqlViewName: string;
  isDefault: number;
  isPinned: boolean;
}

export interface HttpMetadata {
    action: string;
    requestBody: JSONSchema7;
    parameters: JSONSchema7[];
    query: JSONSchema7[];
};

export interface IViewBase {
  modelBaseId?: number | null;
  isDefault: number;
}

export interface INewViewProps {
  reloadViews: (id?: number) => Promise<void>;
  getViews(id?: number | undefined): Promise<void>;
}

export interface IAuditDetailProps {
  itemId: number;
  fieldId: number;
  moduleId: number;
}

export interface AudtiDetailVM {
  id: number;
  afterValue: string;
  beforeValue: string;
  createdOn: Date;
  createdIn: string;
  createdBy: string;
  createdByUserName: string;
  description: string | undefined | null;
  label: string;
  catalogLabel: string | undefined | null;
}

export interface VisibleFieldDataType {
  companyAccountId: null | number;
  description: string;
  id: number;
  isVisible: boolean;
  label: string;
  moduleId: number;
  visibleFieldId: number;
}

export interface VisibleFieldProps {
  dataViewId: number | undefined;
  filter?: () => [];
  onChange: (
    e: CheckboxChangeEvent,
    record: VisibleFieldDataType,
    index: number
  ) => void;
}

export interface FieldSelectType {
  isVisible: boolean;
  lable: string;
}
export interface ModalProps {
  visible: boolean;
  modelBase?: IViewBase;
  hide: () => void;
  reloadViews: (id?: number) => Promise<void>;
  dataView?: IDataView;
}

export interface EditColumnsProps {
  hide: () => void;
  dataViewId: number | undefined;
  visible: boolean;
  companyAccountId: string;
}

export interface DataType {
  index: number;
  label: string;
  key: number;
  fieldId: number;
  companyAccountId: string;
  dataViewId: undefined | number;
  position: number;
}

export type ContactResultType = Record<
  string,
  unknown | Record<string, unknown>
>;

export interface ColumnType {
  dataIndex: string;
  width?: number;
  className?: string;
  title: string;
  render?: (param?: any) => void;
}

export interface IConversationType {
  title: string;
  description: string;
  key: number;
  serviceType: number; // Servicio o Reclamo
  sourceType: number | undefined; // defini si es whatsapp, correo, messenger...
  phoneNumber: string;
  unread: boolean;
}

export interface IChatType {
  conversationKey: number;
  source: string;
  date: Date;
  message: string;
  type: number;
  statusChat?: StatusChat;
}

export interface ClasificationVm {
  selectedKey: number,
  clasificationId: number
}

export interface PagedResultChannelVm {
  currentPage: number;
  firstRowOnPage: number;
  lastRowOnPage: number;
  pageCount: number;
  pageSize: number;
  results: Array<ChannelVm>;
  rowCount: number;
}

export interface ChannelVm {
  id: number;
  mediaId: number;
  name: string;
  uniqueId: string;
  isActive: boolean;
}

export interface ConversationVm {
  id: number;
  entityId: number;
  service: string; // Servicio o Reclamo
  colorTag:string,
  clasification: string;
  entityFullName: string;
  from: string;
  channelName: string | undefined; // defini si es whatsapp, correo, messenger...
  lastMessage: string;
  unread: boolean;
  lastMessageTimeStamp: Date;
}

export interface ServiceVm {
  id: number;
  teamId: number;
  name: string;
  isActive: boolean;
  initials: string;
}

export const newConversationDetailVm = (
  id: number,
  body: string,
  from: string,
  conversationId: number
): ConversationDetailVm => {
  return {
    body: body,
    id: id,
    createdOn: moment(),
    conversationType: ConversationType.Received,
    chatStatus: StatusChat.sending,
    from: from,
    conversationId: conversationId
  };
};

export interface ConversationDetailPagedResult {
  results: Array<ConversationDetailVm>;
  currentPage: number;
  pageCount: number;
  pageSize: number;
  rowCount: number;
  firstRowOnPage: number;
  lastRowOnPage: number;
}

export interface ConversationDetailPage {
  conversationId: number | undefined;
  currentPage: number;
  pageCount: number;
  pageSize: number;
  rowCount: number;
  firstRowOnPage: number;
  lastRowOnPage: number;
}

export interface ConversationDetailVm {
  id: number;
  body: string;
  conversationType: number;
  createdOn: moment.Moment;
  from: string;
  chatStatus?: StatusChat;
  conversationId: number;
  mediaUrl?: string;
  mediaContentType?: string;
}

export declare type RawValueType = string | number;
export declare type Key = string | number;

export interface LabelValueType {
  key?: Key;
  value?: RawValueType;
  label?: React.ReactNode;
  isCacheable?: boolean;
}

export declare type DefaultValueType =
  | RawValueType
  | RawValueType[]
  | LabelValueType
  | LabelValueType[];

export declare type CustomTagProps = {
    label: React.ReactNode;
    value: DefaultValueType;
    disabled: boolean;
    onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    closable: boolean;
};

export interface DynamicData {
    [prop: string]: any;
}

export interface IContactEmail {
  id: number;
  entityId: number;
  companyAccountId: string;
  emailAddress: string;
  isPrimary: boolean;
}

export interface ISaveContactEmail {
  emailVMs: IContactEmail[];
}
