import styled from 'styled-components';

export const Card = styled.div`
    background-color: ${({ theme }) => theme.colorContrast};
    border: 1px solid ${({ theme }) => theme.borderColor};
    padding: .75rem 1rem;
    border-radius: ${({ theme }) => theme.borderRadius};
    margin-bottom: .5rem;
    box-shadow: none;
    transition: all .3s ease;
`;
