import React, { useEffect, useState } from 'react';
import DynamicForm from '../DynamicForms';
import { FormSchema } from '../DynamicForms/FormSchema';
import ContactsService from '../../Services/ContactsService';
import { Col, Row, Spin } from 'antd';
import { ValidationErrors } from '../../Services/Models/ValidationErrors';
import { Result } from '../../Services/Models/Result';
import { ContactResultType } from '../../common/types';
import { ModeForm } from '../../Constants/Enums';

interface ContactFormProps {
  onNameUpdate: (newName: string) => void;
  id?: number;
  modeFormId?: ModeForm;
  onClose?: (forceReaload: boolean) => void;
}

const DynamicContactForm: React.FC<ContactFormProps> = ({ onNameUpdate, onClose, id, modeFormId = ModeForm.Create }: ContactFormProps): JSX.Element => {
  const [formSchema, setFormSchema] = useState({} as FormSchema);
  const [validationErrors, setValidationErrors] = useState({} as ValidationErrors);
  const [serverError, setServerError] = useState({} as Result<number>);
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState<ContactResultType>();

  useEffect(() => {
    const contactsService = new ContactsService();

    const flattenObject = (obj: any): IObjectKeys => {
      const flattened: IObjectKeys = {};

      Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          let currentObj = obj[key];
          if (obj[key]?.length > 1) {
            const onlyPrimary = obj[key].filter((item: any) => item.isPrimary === true);
            currentObj = onlyPrimary?.length > 0 ? onlyPrimary : currentObj;
          }
          Object.assign(flattened, flattenObject(currentObj));
        } else {
          flattened[key] = obj[key];
        }
      });

      return flattened;
    };

    const init = async (): Promise<void> => {
      const result = await contactsService.getForm();
      setFormSchema(result);
      if (id) {
        const contactData = await contactsService.getContact(id);
        const flatData = flattenObject(contactData);
        setData(flatData);
        onNameUpdate((contactData as Record<string, unknown>).firstName as string);
      }

      setLoading(false);
    };

    init();
  }, [id, onNameUpdate]);

  interface IObjectKeys {
    [key: string]: string | number;
  }

  const onSubmit = async (data: { [x: string]: unknown }): Promise<void> => {
    console.log('Saving Contact with Data', data);
    setLoading(true);
    const contactsService = new ContactsService();

    try {
      if (modeFormId === ModeForm.Edit || id) { data.id = id; };
      const result = modeFormId === ModeForm.Create ? await contactsService.save(data) : await contactsService.edit(data);
      if (!result.IsSuccess) {
        setLoading(false);
        setServerError(result as Result<number>);
        return;
      }

      setValidationErrors({} as ValidationErrors);
      setServerError({} as Result<number>);

      onNameUpdate('');
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      if (e.status === 400) setValidationErrors((e.data as ValidationErrors));
      else setServerError(e.data as Result<number>);
    }
  };

  const onReset = async (): Promise<void> => {
    if (onClose) onClose(false);
    setLoading(false);
  };

  const loadingComponent = <Row>
    <Col span={24}>
      <Spin style={{ display: 'flex', justifyContent: 'center', margin: '20px' }} />
    </Col>
  </Row>;

  return isLoading
    ? loadingComponent
    : <DynamicForm
      onSubmit={onSubmit}
      onReset={onReset}
      submitButtonText={modeFormId === ModeForm.Create ? 'contactForm.create' : 'contactForm.edit'}
      cancelButtonText={'cancel'}
      validationErrors={validationErrors}
      serverErrors={serverError}
      schema={formSchema}
      data={data}
    />;
};

export default DynamicContactForm;
