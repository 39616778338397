import React, { MouseEvent } from 'react';
import { Layout } from 'antd';
import { SiderProps } from 'antd/es/layout/Sider';
let isResizing: boolean | null = null;

type sideGrowType = 'right' | 'left';

interface ResizebleDrawerProps extends SiderProps {
  children: React.ReactNode;
  minWidth: number;
  maxWidth: number;
  onResize: (sizePx: number) => void;
  sideGrow?: sideGrowType;
}

const SiderResisable: React.FC<ResizebleDrawerProps> = ({ children, width, onResize, maxWidth, minWidth, sideGrow = 'right', ...props }: ResizebleDrawerProps) => {
  const { Sider } = Layout;

  function handleMousemove (e: globalThis.MouseEvent): void {
    const offsetRight = sideGrow === 'right' ? document.body.offsetLeft - (document.body.offsetLeft - e.clientX) : document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
    if (offsetRight > minWidth && offsetRight < maxWidth) {
      onResize(offsetRight);
    }
  }

  const cbHandleMouseMove = React.useCallback(handleMousemove, [minWidth, maxWidth, sideGrow, onResize]);
  const cbHandleMouseUp = React.useCallback(() => {
    function handleMouseup (): void {
      if (!isResizing) {
        return;
      }
      isResizing = false;
      document.removeEventListener('mousemove', cbHandleMouseMove);
      document.removeEventListener('mouseup', cbHandleMouseUp);
    }
    handleMouseup();
  }, [cbHandleMouseMove]);

  function handleMousedown (e: MouseEvent<HTMLDivElement>): void {
    e.stopPropagation();
    e.preventDefault();

    document.addEventListener('mousemove', cbHandleMouseMove);
    document.addEventListener('mouseup', cbHandleMouseUp);
    isResizing = true;
  }

  return (
    sideGrow === 'right'
      ? <Sider width={width} {...props}>
        {children}
        <div className='sidebar-right' onMouseDown={handleMousedown} />
      </Sider>
      : <Sider width={width} {...props}>
        <div
          className='sidebar-dragger'
          onMouseDown={handleMousedown} />
        {children}
      </Sider>

  );
};

export default SiderResisable;
