import { FormSchema } from '../Components/DynamicForms/FormSchema';
import { IPipeline, IPipelineStat, IPipelineStatResponse } from '../models/Opportunities';
import APIClient from './APIClient';
import { Result } from './Models/Result';

export default class OpportunitiesService extends APIClient {
  constructor (url: string = process.env.REACT_APP_API_BASE_URL as string) {
    super(url);
  };

  async getPipelineStats (id: number): Promise<IPipelineStat[]> {
    const { data } = await this.get<IPipelineStatResponse[]>(`Pipeline/Stats?pipelineId=${id}`);

    // Transform the data to display the stat cards only for the Internal stages
    // No internal stages won't be necessary at this time
    const result: IPipelineStat[] = [];
    const internalStages = data.filter(stage => stage.isInternal === true);

    // for each stage insert 2 values: % and quantity
    internalStages.forEach(stage => {
      result.push({
        label: `Porcentaje ${stage.stageName}s`,
        value: +stage.percentage.toFixed(2),
        suffix: '%'
      });

      result.push({
        label: `Cantidad ${stage.stageName}s`,
        value: stage.quantity
      });
    });

    return new Promise(resolve => resolve(result));
  }

  async getDefaultUserPipeline (): Promise<IPipeline> {
    const { data } = await this.get<IPipeline>('Pipeline/UserDefault');

    console.log(data);

    return new Promise(resolve => resolve(data));
  }

  async saveTransition (opportunityId: number, newStage: number, formValues?: { [x: string]: unknown }): Promise<Result<number>> {
    const { data } = await this.put<Result<number>>(`Opportunity/${opportunityId}/Transition`, { newStage, ...formValues });

    return new Promise(resolve => resolve(data));
  }

  async getCloseForm (): Promise<FormSchema> {
    const { data } = await this.get<FormSchema>('Opportunity/CloseForm');
    return data;
  }
}
