import React from 'react';
// import { useTranslation } from "react-i18next";

const Accounts: React.FC = () => {
  // const { t } = useTranslation();

  return (
    <>
      {/* <Row className="justify-content-between">
                <Col md="4">
                    <h2>{t('accounts')}</h2>
                </Col>
                <Col md="3">
                    <InputGroup className="mb-3">
                        <FormControl
                            placeholder={t('searchAccounts')}
                            aria-label={t('searchAccounts')}
                            aria-describedby="search-account"
                        />
                        <InputGroup.Append>
                            <InputGroup.Text id="search-account">
                                <FontAwesomeIcon icon={faSearch} />
                            </InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
            </Row>

            <hr />

            <Row className="mb-4">
                <Col className="d-flex align-items-center">
                    <Form.Label>{t('compareSessions')}:</Form.Label>
                    <Col md="2" sm="6" xs="12">
                        <Form.Control as="select">
                            <option>{t('weekOverWeek')}</option>
                        </Form.Control>
                    </Col>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Table bordered hover>
                        <thead>
                            <tr>
                                <th className="text-uppercase">{t('name')}</th>
                                <th className="text-uppercase">{t('domain')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="text-primary">
                                    <FontAwesomeIcon icon={faThumbtack} />
                                    <span> Ahinko</span>
                                </td>
                                <td>
                                    <p className="m-0 p-0">site.com</p>
                                    <p className="m-0 p-0"><small>123456</small></p>
                                </td>
                            </tr>
                            <tr>
                                <td className="text-primary">
                                    <FontAwesomeIcon icon={faThumbtack} />
                                    <span> Ahinko</span>
                                </td>
                                <td>
                                    <p className="m-0 p-0">site.com</p>
                                    <p className="m-0 p-0"><small>123456</small></p>
                                </td>
                            </tr>
                            <tr>
                                <td className="text-primary">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <span> Ahinko</span>
                                        <div>
                                            <Button variant="light" className="mr-2">{t('pin')}</Button>
                                            <Button variant="light">
                                                <span>{t('open')} </span>
                                                <FontAwesomeIcon icon={faExternalLinkAlt}/>
                                            </Button>
                                        </div>
                                    </div>

                                </td>
                                <td>
                                    <p className="m-0 p-0">site.com</p>
                                    <p className="m-0 p-0"><small>123456</small></p>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row> */}
    </>
  );
};

export default Accounts;
