import React, { BaseSyntheticEvent } from 'react';

interface props {
  onIconClick: (e: BaseSyntheticEvent) => void;
}

export const IconsTable: React.FC<props> = ({ onIconClick }: props) => {
  const tdStyle = { cursor: 'pointer' };

  return (
    <div>
      <table>
        <tr>
          <td style={tdStyle} onClick={onIconClick}>&#128512;</td>
          <td style={tdStyle} onClick={onIconClick}>&#128527;</td>
          <td style={tdStyle} onClick={onIconClick}>&#9785;</td>
          <td style={tdStyle} onClick={onIconClick}>&#128513;</td>
          <td style={tdStyle} onClick={onIconClick}>&#128514;</td>
          <td style={tdStyle} onClick={onIconClick}>&#128520;</td>
          <td style={tdStyle} onClick={onIconClick}>&#128524;</td>
          <td style={tdStyle} onClick={onIconClick}>&#128525;</td>
          <td style={tdStyle} onClick={onIconClick}>&#128526;</td>
        </tr>
        <tr>
          <td style={tdStyle} onClick={onIconClick}>&#128521;</td>
          <td style={tdStyle} onClick={onIconClick}>&#128522;</td>
          <td style={tdStyle} onClick={onIconClick}>&#128522;</td>
          <td style={tdStyle} onClick={onIconClick}>&#128515;</td>
          <td style={tdStyle} onClick={onIconClick}>&#128516;</td>
          <td style={tdStyle} onClick={onIconClick}>&#128517;</td>
          <td style={tdStyle} onClick={onIconClick}>&#128518;</td>
          <td style={tdStyle} onClick={onIconClick}>&#128519;</td>
          <td style={tdStyle} onClick={onIconClick}>&#128523;</td>
        </tr>
        <tr>
          <td style={tdStyle} onClick={onIconClick}>😥</td>
          <td style={tdStyle} onClick={onIconClick}>😨</td>
          <td style={tdStyle} onClick={onIconClick}>😩</td>
          <td style={tdStyle} onClick={onIconClick}>😪</td>
          <td style={tdStyle} onClick={onIconClick}>😫</td>
          <td style={tdStyle} onClick={onIconClick}>😭</td>
          <td style={tdStyle} onClick={onIconClick}>😰</td>
          <td style={tdStyle} onClick={onIconClick}>😱</td>
          <td style={tdStyle} onClick={onIconClick}>&#128534;</td>
        </tr>
        <tr>
          <td style={tdStyle} onClick={onIconClick}>😶</td>
          <td style={tdStyle} onClick={onIconClick}>&#128528;</td>
          <td style={tdStyle} onClick={onIconClick}>&#128529;</td>
          <td style={tdStyle} onClick={onIconClick}>&#128530;</td>
          <td style={tdStyle} onClick={onIconClick}>&#128531;</td>
          <td style={tdStyle} onClick={onIconClick}>&#128532;</td>
          <td style={tdStyle} onClick={onIconClick}>&#128533;</td>
          <td style={tdStyle} onClick={onIconClick}>&#128535;</td>
          <td style={tdStyle} onClick={onIconClick}>&#128536;</td>
        </tr>
        <tr>
          <td style={tdStyle} onClick={onIconClick}>😲</td>
          <td style={tdStyle} onClick={onIconClick}>😳</td>
          <td style={tdStyle} onClick={onIconClick}>😵</td>
          <td style={tdStyle} onClick={onIconClick}>😷</td>
          <td style={tdStyle} onClick={onIconClick}>&#128539;</td>
          <td style={tdStyle} onClick={onIconClick}>&#128538;</td>
          <td style={tdStyle} onClick={onIconClick}>&#128537;</td>
          <td style={tdStyle} onClick={onIconClick}>😹</td>
          <td style={tdStyle} onClick={onIconClick}>😻</td>
        </tr>
        <tr>
          <td style={tdStyle} onClick={onIconClick}>😸</td>
          <td style={tdStyle} onClick={onIconClick}>😺</td>
          <td style={tdStyle} onClick={onIconClick}>😼</td>
          <td style={tdStyle} onClick={onIconClick}>😽</td>
          <td style={tdStyle} onClick={onIconClick}>😾</td>
          <td style={tdStyle} onClick={onIconClick}>😿</td>
          <td style={tdStyle} onClick={onIconClick}>🙀</td>
          <td style={tdStyle} onClick={onIconClick}>🙈</td>
          <td style={tdStyle} onClick={onIconClick}>🙉</td>

        </tr>
        <tr>
          <td style={tdStyle} onClick={onIconClick}>🙊</td>
          <td style={tdStyle} onClick={onIconClick}>🙆</td>
          <td style={tdStyle} onClick={onIconClick}>🙇</td>
          <td style={tdStyle} onClick={onIconClick}>🙅</td>
          <td style={tdStyle} onClick={onIconClick}>🙋</td>
          <td style={tdStyle} onClick={onIconClick}>🙌</td>
          <td style={tdStyle} onClick={onIconClick}>🙍</td>
          <td style={tdStyle} onClick={onIconClick}>🙎</td>
          <td style={tdStyle} onClick={onIconClick}>🙏</td>
        </tr>
      </table>
    </div>
  );
};
