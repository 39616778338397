import { Modal, message, Input, Form, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import APIClient from '../../Services/APIClient';
import { useAuth } from 'oidc-react';
import { ModuleId, ShareTypeId } from '../../Constants/Enums';
import { ModalProps } from '../../common/types';
import { AxiosResponse } from 'axios';

const CreateView: React.FC<ModalProps> = (
  {
    visible,
    hide,
    reloadViews,
    modelBase,
    dataView
  }: ModalProps) => {
  const { t } = useTranslation();
  const [confirmLoading, setConfirmLoading] = useState(false);

  const auth = useAuth();

  async function handleOk (values: Record<string, unknown>): Promise<void> {
    try {
      if (auth?.userData?.access_token) {
        setConfirmLoading(true);
        const apiClient = new APIClient();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let result: AxiosResponse<any>;
        if (dataView !== null && dataView !== undefined) {
          const { companyAccountId, id, moduleId, sqlViewName, isDefault } = dataView;
          result = await apiClient.client.put('/DataView', { ...values, companyAccountId, id, moduleId, sqlViewName, isDefault });
        } else { result = await apiClient.client.post('/DataView', { SQLViewName: '', moduleId: ModuleId.Contacto, ...modelBase, ...values }); }

        if (result) {
          message.success(t('viewCreated'), 5);
        } else {
          message.error(t('viewCreatedError'), 5);
        }
        hide();
        reloadViews(result.data.Value);
        setConfirmLoading(false);
      }
    } catch (err) { console.error(err); }
  }

  const [form] = Form.useForm();
  const onKeyInput = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (dataView === undefined && e.code === 'Space') {
      const [start, end] = [e.currentTarget.selectionStart, e.currentTarget.selectionEnd];
      e.currentTarget.setRangeText(' ', start ?? 0, end ?? 0, 'end');
    }
  };
  useEffect(() => {
    form.resetFields();
    setConfirmLoading(false);
    if (dataView !== null && dataView !== undefined) { form.setFieldsValue({ name: dataView?.name, shareTypeId: dataView?.shareTypeId }); }
  }, [modelBase, hide, dataView, form]);
  return (
    <>
      <Modal
        title={dataView !== null && dataView !== undefined ? t('editView') : t('createNewView')}
        confirmLoading={confirmLoading}
        width={600}
        onCancel={hide}
        okText={dataView !== null && dataView !== undefined ? t('save') : t('create')}
        cancelText={t('cancel')}
        visible={visible}
        destroyOnClose={true}
        onOk={() => {
          form.validateFields()
            .then(values => {
              handleOk(values).then(() => form.resetFields());
            });
        }}
      >
        <Form form={form}>
          <Form.Item name='name' rules={[{ required: true, message: t('insertViewName') }]}>
            <Input onKeyDown={onKeyInput} size='large' allowClear={true} placeholder={t('viewName')} prefix={<EditOutlined />} required={true} inputMode='text' />
          </Form.Item>
          <span style={{ fontWeight: 'bold' }}>{t('shareAs')}</span>
          <Form.Item name='shareTypeId' rules={[{ required: true, message: t('selectShareType') }]}>
            <Radio.Group>
              <Radio value={ShareTypeId.Privado}>{t('private')}</Radio>
              <Radio value={ShareTypeId.Publico}>{t('public')}</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </>);
};

export default CreateView;
