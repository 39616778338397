import { useState } from 'react';

interface returnType {
  isShown: boolean;
  toggle: () => void;
}
export const useModal = (): returnType => {
  const [isShown, setIsShown] = useState<boolean>(false);
  const toggle = (): void => setIsShown(!isShown);
  return {
    isShown,
    toggle
  };
};
