import { PagedResultChannelVm } from '../common/types';
import APIClient from './APIClient';

export default class ChannelService extends APIClient {
  async getChannels (pageNumber: number, pageSize: number): Promise<PagedResultChannelVm> {
    const { data } = await this.get<PagedResultChannelVm>(
      `Channel?pageSize=${pageSize}&pageNumber=${pageNumber}`
    );
    return data;
  }
}
