import { FileTextOutlined, PlusOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { Avatar, Col, Row, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import '../Assets/Styles/contactHeader.less';

interface ContactHeaderProps {
  name?: string;
  avatarSrc?: string;
}

const ContactHeader: React.FC<ContactHeaderProps> = ({ name, avatarSrc }: ContactHeaderProps) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col span={24}>
        <div className='contact-header'>
          <div className='avatar-circle-border'>
            <Avatar size={80} src={avatarSrc || '/default-avatar.png'} />
          </div>
          <div className='contact-information'>
            <h2>{name || t('newContact')}</h2>
            <div className='icon-action-group' style={{ display: 'none' }}>
              <Tooltip placement='top' title={t('createNote')}>
                <FileTextOutlined />
              </Tooltip>
              <Tooltip placement='top' title={t('sendEmail')}>
                <i className='las la-envelope-open-text'></i>
              </Tooltip>
              <Tooltip placement='top' title={''}>
                <i className='las la-check-square'></i>
              </Tooltip>
              <Tooltip placement='top' title={t('logActivity')}>
                <PlusOutlined />
              </Tooltip>
              <Tooltip placement='top' title={t('scheduleMeeting')}>
                <i className='las la-calendar'></i>
              </Tooltip>
              <Tooltip placement='top' title={t('sendWhatsappMessage')}>
                <WhatsAppOutlined />
              </Tooltip>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ContactHeader;
