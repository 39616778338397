import { EditOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidationErrors } from '../../Services/Models/ValidationErrors';
import DynamicFieldSet from './DynamicFieldSet';
import { FieldProps } from './FieldProps';

interface ModalDymanicFieldProps {
  entityId: number;
  companyAccountId: string;
  field: FieldProps;
  validationErrors?: ValidationErrors;
}

export const ModalDynamicField: React.FC<ModalDymanicFieldProps> = ({ entityId, companyAccountId, field, validationErrors }: ModalDymanicFieldProps) => {
  const { t } = useTranslation();
  const [visibleModal, setVisibleModal] = useState(false);

  const handleEditClick = (): void => {
    setVisibleModal(true);
  };

  const hideModal = (): void => {
    setVisibleModal(false);
  };

  return (
    <>
      <Button icon={<EditOutlined />} onClick={handleEditClick} />
      <Modal
        visible={visibleModal}
        title={t('contactForm.' + field.internalName + '.placeholder')}
        zIndex={1040}
        onCancel={hideModal}
        footer={null}
      >
        <DynamicFieldSet entityId={entityId} companyAccountId={companyAccountId} field={field} />
      </Modal>
    </>
  );
};
